import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Section3 from './section3';
import Section2 from './section2';
import Section4 from './section4';
import Section5 from './section5';
import Section6 from './section6';
import Enroll from './enroll';
import Logo1 from '/Users/adnan/Desktop/w2/client/src/images/logo1.svg'
import '/Users/adnan/Desktop/w2/client/src/App.css';
import gamel from '/Users/adnan/Desktop/w2/client/src/images/gamel.svg'
import main from '/Users/adnan/Desktop/w2/client/src/images/home-images/main.svg'

export default function Section1() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
}, []);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const elements = document.querySelectorAll('.drop-main div');

    elements.forEach((element, index) => {
      setTimeout(() => {
        element.classList.add('animate-drop');
      }, index * 200); // Adjust the delay as needed
    });
  }, []);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className='bg-[#ece5c7]'>
    <nav className="p-2 fixed top-0 left-0 w-full z-10">
      <div className="container flex justify-between items-center">
        <div className='flex border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2'>
          <div className='flex justify-center items-center'>
          <img className='w-8 h-8 rounded-full mx-1' src={Logo1} alt=''/>
          </div>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <div className="drop-main">
          <div className="S animate-ease">S</div>
            <div className="T animate-ease">t</div>
            <div className="A animate-ease">a</div>
            <div className="K animate-ease">k</div>
            <div className="E animate-ease">e</div>
            <div className="P animate-ease">p</div>
            <div className="L animate-ease">l</div>
            <div className="O animate-ease">o</div>
            <div className="T animate-ease">t</div>
          </div>
          </div>
        </div>
        </div>
        <div className="hidden md:flex border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2">
          <a href='#how-it-works' className="text-[#116a7b] underline-animation m-2">Method</a>
          <Link to='/blogs' className="text-[#116a7b] underline-animation m-2">Blogs</Link>
          <Link to='/calculators' className="text-[#116a7b] underline-animation m-2">Calculators</Link>
        </div>
        <div className="md:hidden flex items-center pr-6">
          <button onClick={toggleMenu} className="text-[#116a7b] focus:outline-none">
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
            </svg>
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className=" w-[100%] z-10 md:hidden bg-opacity-20 bg-white backdrop-blur-md rounded-2xl border-white p-2">
        <a href='#how-it-works' className="text-[#116a7b] underline-animation ">Method</a>
        <Link to='/blogs' className="block py-2 text-gray-800 transition duration-300">
          <div className='flex'>
          <h2 className='underline-animation text-[#116a7b] flex-wrap'>Blogs</h2>
          </div>
          </Link>
        <div className="relative" >
        <Link to='/calculators' className="block py-2 text-gray-800 transition duration-300">
          <div className='flex'>
          <h2 className='underline-animation text-[#116a7b] flex-wrap'>Calculators</h2>
          </div>
          </Link>
        </div>
      </div>
      )}
    </nav>
    <div className='relative grid grid-cols-1 lg:grid-cols-2 lg:pt-8'>
  <div className='absolute inset-0 bg-[#709B97] lg:hidden' style={{ clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' }}></div>
  <div className='absolute inset-0 bg-[#ECE5C7] lg:hidden animate-slide-in' style={{ clipPath: 'polygon(0 15%, 100% 0, 100% 90%, 0 15%)' }}></div>
  <div className='absolute inset-0 bg-[#709B97] hidden lg:block' style={{ clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' }}></div>
  <div className='absolute inset-0 bg-[#ECE5C7] hidden lg:block animate-slide-in' style={{ clipPath: 'polygon(0 45%, 100% 0, 100% 100%, 0 45%)' }}></div>
  <div className='relative lg:mt-10'>
    <div className='flex justify-center items-center'>
      <div>
      <h2 className='pt-[95px] pl-2 text-6xl'>Transform your money habits with stakeplot</h2>
      <h1 className='p-2 lg:text-2xl'>Fuel Your Dreams, Fuel Your Wallet: Your Journey to Financial Success Starts Here.</h1>
      <div className="p-2 flex flex-wrap">
      <h1 className="text-4xl md:text-6xl font-extrabold text-black ">
        {['C', 'o', 'm', 'i', 'n', 'g', ' ', 'S', 'o', 'o', 'n', '..'].map((char, index) => (
          <span
            key={index}
            className={`inline-block animate-wave`}
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            {char}
          </span>
        ))}
      </h1>
      </div>
      </div>
    </div>
    </div>
      <div className='relative flex justify-center mt-[30px]'>
        <img className='w-[60%] md:w-[50%] h-[350px]' src={main} alt='Manage all your expenses at one place'></img>
      </div>
    </div>
    
    <div className=' rounded-b-3xl bg-[#709B97]'>
      <Enroll></Enroll>
      <div className='grid grid-cols-2 p-2'>
        <div className='flex justify-center items-center'>
      <div className='ml-4'>
        <h1 className='font-sans text-3xl text-[#334B49]'>CashCraft </h1>
        <span className='text-xs text-[#334B49]'>by stakeplot</span>
        <h1 className=' md:text-2xl text-white'>MoneyMaster is your ticket to financial freedom! Dive into a world of financial strategy and decision-making skill game.</h1>
      <Link to='/budgeting'>
        <div className='gamebox flex justify-center items-center p-4 w-[100%] lg:w-[80%] m-2 '>
          <h1 className='text-2xl hover:text-3xl'>Dive in</h1>
        </div>
      </Link>
      </div>
      </div>
      <div className='flex justify-center'>
      <div className='md:w-[60%]'>
        <img src={gamel} alt='gamel'></img>
      </div>
      </div>
      </div>
    </div>
    <Section2></Section2>
    <div id='how-it-works'>
    <Section3></Section3>
    </div>
    <Section4></Section4>
    <Section5></Section5>
    <Section6></Section6>
    </div>
  );
}
