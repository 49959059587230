// Partnerhome.js
import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {default as api} from '../store/apiSlice'
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode directly
import down  from '/Users/adnan/Desktop/w2/client/src/images/down-vote.svg'
import up from '/Users/adnan/Desktop/w2/client/src/images/up-vote.svg'
import PartnerPerformance from './partnerPerformance';

export default function Partnerhome() {
  const [emailAddress, setemailAddress] = useState(null); // State to store username
  const token = localStorage.getItem('token'); // Retrieve token from local storage

  useEffect(() => {
    if (token) {
      // Decode token to extract username
      const decodedToken = jwtDecode(token); // Use jwtDecode
      setemailAddress(decodedToken.emailAddress);
    }
  }, [token]);

  const { data: offerData, isLoading: offerIsLoading, isError: offerIsError, refetch: refetchOffers } = api.useGetOfferQuery(emailAddress);
  const { data: couponData, isLoading: couponIsLoading, isError: couponIsError, refetch: refetchCoupons } = api.useGetCouponsQuery(emailAddress);
  

  useEffect(() => {
    if (offerIsError || couponIsError) {
      // Handle error if needed
    }
  }, [offerIsError, couponIsError]);

  if (offerIsLoading || couponIsLoading) {
    return <div>Loading...</div>;
  }

  if (offerIsError || couponIsError) {
    return <div>Error</div>;
  }

  return (
    <div>
      <PartnerComponent offerData={offerData} couponData={couponData} refetchOffers={refetchOffers} refetchCoupons={refetchCoupons} />
    </div>
  );
}

function PartnerComponent({ offerData, couponData, refetchOffers, refetchCoupons }) {
  const [updateStatus] = api.useUpdateStatusMutation();
  const [deleteOffer] = api.useDeleteOfferMutation()
  const [visible,setVisible] = useState(false)


  const toggleIsActive = async (offerId, currentStatus) => {
    try {
      // Toggle the isActive status
      const updatedOffer = { id: offerId, isActive: !currentStatus };
      toast.success('status updated..!')
      await updateStatus(updatedOffer);
      // Refetch offers after updating
      refetchOffers();
    } catch (error) {
      console.error('Error toggling isActive status:', error);
    }
  };
  const deleteoffer = async(offerid) => {
    try{
      const deletingoffer = { id: offerid}
      await deleteOffer(deletingoffer)
      toast.success("order deleted successfully..!")
      refetchOffers();
    } catch (error) {
      toast.error("error while deleting offer", error)
    }
  }
  const isDeletable = (createdAt) => {
    const offerDate = new Date(createdAt);
    const currentDate = new Date();
    const diffInHours = (currentDate - offerDate) / 1000 / 60 / 60;
    return diffInHours <= 48;
  };
  const visibility = (offerId) => {
    setVisible((prev) => ({...prev,[offerId] : !prev[offerId]}))
  }
  return (
    <div>
      <div className='flex justify-between'>
        <div>
        <div className="container  mx-auto flex justify-between items-center">
        <div className='border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2'>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <Link to='/'>
          <div className="drop-main">
          <div className="S animate-ease">S</div>
            <div className="T animate-ease">t</div>
            <div className="A animate-ease">a</div>
            <div className="K animate-ease">k</div>
            <div className="E animate-ease">e</div>
            <div className="P animate-ease">p</div>
            <div className="L animate-ease">l</div>
            <div className="O animate-ease">o</div>
            <div className="T animate-ease">t</div>
          </div>
          </Link>
          </div>
        </div>
        </div>
      </div>
        </div>
        
      <div className='flex text-2xl p-2 m-2'>
      <div className='text-2xl px-2 mx-2 border border-black'>
        <Link to="/createoffer">Create Offer</Link>
        </div>
        <div className='text-2xl px-2 border border-black'>
        <Link to='/profileinfo'>Profile</Link>
        </div>
      </div>
      </div>
      <PartnerPerformance></PartnerPerformance>
      {offerData.map(offer => (
        <div key={offer._id} className="bg-white p-6 my-4 rounded-lg shadow-lg transition-transform transform hover:-translate-y-1">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div className="col-span-2">
            <div className="space-y-2">
              <div className="flex justify-between">
                <h1 className="font-bold">Product name</h1>
                <span>{offer.productName}</span>
              </div>
              <div className="flex justify-between">
                <h1 className="font-bold">Product Link</h1>
                <span>{offer.productLink}</span>
              </div>
              <div className="flex justify-between">
                <h2 className="font-medium">Actual Price</h2>
                <span>{offer.actualPrice}</span>
              </div>
              <div className="flex justify-between">
                <h2 className="font-medium">Least offered price</h2>
                <span>{offer.leastPrice}</span>
              </div>
              <div className="flex justify-between">
                <h2 className="font-medium">Amount of Items listed</h2>
                <span>{offer.numberofitems}</span>
              </div>
              <div className="flex justify-between">
                <h2 className="font-medium">Created on</h2>
                <div>
                  <div>{new Date(offer.date).toLocaleDateString()}</div>
                  <div>{new Date(offer.date).toLocaleTimeString()}</div>
                </div>
              </div>
              <div className="flex justify-between">
                <p className="font-medium">Active: {offer.isActive ? 'Yes' : 'No'}</p>
                <button className="font-medium text-xs py-1 px-2 bg-blue-500 text-white rounded-md" onClick={() => toggleIsActive(offer._id, offer.isActive)}>
                  {offer.isActive ? 'Deactivate' : 'Activate'}
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center space-y-4">
          <div className="flex border border-gray-500 rounded-md p-2 flex-col justify-center space-y-4">
            <h1>Product overview :</h1>
      <div className="flex justify-between items-center text-lg bg-[#709B97] rounded-lg p-4">
        <div>Views</div>
        <div>90</div>
      </div>
      <div className="flex justify-between items-center text-lg bg-[#709B97] rounded-lg p-4">
        <div>Impressions :</div>
        <div>90008909</div>
      </div>
      <div className="flex justify-between items-center text-lg bg-[#709B97] rounded-lg p-4">
        <div>Leads</div>
        <div>90</div>
      </div>
    </div>
            <img
              src={offer.productImage} // Assuming offer.productImage contains the URL of the image
              alt="Product"
              className="w-40 h-40 object-cover rounded-lg shadow-md"
              onError={(e) => {
                console.error('Error loading image:', e.target.src);
                e.target.style.display = 'none'; // Hide the image if loading fails
              }}
            />
            {/* Display a placeholder if offer.productImage is not available */}
            {!offer.productImage && (
              <div className="w-40 h-40 flex items-center justify-center bg-gray-200 rounded-lg">
                No image available
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <div className="grid grid-cols-3 gap-4">
            <h1 className="font-bold">Stage 1 pricing</h1>
            <h1 className="font-bold">Stage 2 pricing</h1>
            <h1 className="font-bold">Stage 3 pricing</h1>
          </div>
          <div className="grid grid-cols-3 gap-4 mt-2">
            <h1>{offer.stage1price}</h1>
            <h1>{offer.stage2price}</h1>
            <h1>{offer.stage3price}</h1>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex justify-between items-center">
            <h3 className="font-bold">Coupons:</h3>
            <button onClick={() => visibility(offer._id)}>
              <img className="w-5 h-5" src={visible[offer._id] ? up : down} alt="toggle coupons" />
            </button>
          </div>
          {visible[offer._id] && (
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-2 border border-gray-200 p-4 rounded-lg">
              {[1000, 2500, 5000].map(value => (
                <div key={value}>
                  <h4 className="font-bold">Value: {value} ({couponData.filter(coupon => coupon.productId === offer._id && coupon.value === value).length} coupons)</h4>
                  <div className="mt-2 space-y-1">
                    {couponData.filter(coupon => coupon.productId === offer._id && coupon.value === value).map(coupon => (
                      <div key={coupon._id}>
                        <p>Code: {coupon.code}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <button onClick={() => deleteoffer(offer._id)} disabled={!isDeletable(offer.date)} className={`mt-4 py-2 px-4 rounded-md ${isDeletable(offer.date) ? 'bg-red-500 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}>
          {isDeletable(offer.date) ? 'Delete offer' : 'Cannot delete offer'}
        </button>
      </div>
      
      ))}
            
    </div>
  );
}
