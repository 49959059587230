import React from 'react'
import '/Users/adnan/Desktop/w2/client/src/App.css'
import track from '/Users/adnan/Desktop/w2/client/src/images/track.jpg'
import debts from '/Users/adnan/Desktop/w2/client/src/images/debts.jpg'
import target from '/Users/adnan/Desktop/w2/client/src/images/target.jpg'
import hub from '/Users/adnan/Desktop/w2/client/src/images/hub.jpg'
import community from '/Users/adnan/Desktop/w2/client/src/images/community.jpg'


export default function Section2() {
  return (
    <div>
      <div className="w-[100%] py-2">
        <ul id="cards2">
            <li className="card2" id="card1">
            <div className="card2-body">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 m-auto">
                <div className="m-2 flex justify-center md:items-center"> {/* Added items-center class */}
                  <img className="p-2 h-[400px] md:h-[550px]" src={track} alt="how to make a monthly budget" /> {/* Adjusted image classes */}
                </div>
                <div className="md:mt-[20%] md:flex justify-center items-center">
                  <div>
                    <div className='flex'>
                    <h2 className=' p-1 text-4xl'>Personal Finance Manager</h2>
                    </div>
                    <div className='flex justify-center'>
                    <h3 className='p-1 lg:text-xl'>Take control of your financial future with Finance Mastery, the ultimate app for tracking, saving, managing, and planning all your expenses effortlessly.</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </li>
            <li className="card2" id="card2">
                <div className="card2-body">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 m-auto">
                <div className="m-2 flex justify-center md:items-center"> {/* Added items-center class */}
                  <img className="p-2 h-[400px] md:h-[550px]" src={debts} alt="Money management app" /> {/* Adjusted image classes */}
                </div>
                <div className="md:mt-[20%] md:flex justify-center items-center">
                  <div>
                    <div className='flex '>
                    <h2 className=' p-1 text-4xl'>Avoid Penalties and Save Money on Debts</h2>
                    </div>
                    <div className='flex justify-center'>
                    <h3 className='lg:text-xl'>Unlock the power of Smart Debt Management to effortlessly avoid penalties and save money on your debts.</h3>
                    </div>
                  </div>
                </div>
              </div>
                </div>
            </li>
            <li className="card2" id="card3">
                <div className="card2-body">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 m-auto">
                <div className="m-2 flex justify-center md:items-center"> {/* Added items-center class */}
                  <img className="p-2 h-[400px] md:h-[550px]" src={target} alt="monthly Budget planner" /> {/* Adjusted image classes */}
                </div>
                <div className="md:mt-[20%] md:flex justify-center items-center">
                  <div>
                    <div className='flex'>
                    <h2 className=' p-1 text-4xl'>Set Targets, Schedule Payments, Never Miss a Date</h2>
                    </div>
                    <div className='flex justify-center'>
                    <h3 className='lg:text-xl'>Achieve financial precision with our app by setting your finance targets, scheduling payments, and never missing a due date.</h3>
                    </div>
                  </div>
                </div>
              </div>
                </div>
            </li>
            <li className="card2" id="card4">
                <div className="card2-body">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 m-auto">
                <div className="m-2 flex justify-center md:items-center"> {/* Added items-center class */}
                  <img className="p-2 h-[400px] md:h-[550px]" src={hub} alt="Best Budgeting app" /> {/* Adjusted image classes */}
                </div>
                <div className="md:mt-[20%] md:flex justify-center items-center">
                  <div>
                    <div className='flex'>
                    <h2 className=' p-1 text-4xl text-white'>Learn About Finances the Fun Way</h2>
                    </div>
                    <div className='flex justify-center'>
                    <h3 className='text-white lg:text-xl'>Discover tips, tricks, and insights that empower you to take control of your financial future while having fun.</h3>
                    </div>
                  </div>
                </div>
              </div>
                </div>
            </li>
            <li className="card2" id="card5">
                <div className="card2-body">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 m-auto">
                <div className="m-2 flex justify-center md:items-center"> {/* Added items-center class */}
                  <img className="p-2 h-[400px] md:h-[550px]" src={community} alt="Finance plan" /> {/* Adjusted image classes */}
                </div>
                <div className="md:mt-[20%] md:flex justify-center items-center">
                  <div>
                    <div className='flex '>
                    <h2 className=' p-1 text-4xl text-white'>Community</h2>
                    </div>
                    <div className='flex justify-center'>
                    <h3 className='text-white lg:hidden'>Our vibrant community feature where you can interact with others, share experiences, and stay socially active.</h3>
                    <h3 className='text-white hidden lg:block text-xl'>Our vibrant community feature where you can interact with others, share experiences, and stay socially active. Participate in lively discussions, join interested discussions, and connect with like-minded individuals. Share your achievements, seek advice, and build lasting friendships in a supportive and engaging environment.</h3>
                    </div>
                  </div>
                </div>
              </div>
                </div>
            </li>
        </ul>
    </div>
    </div>
  )
}
