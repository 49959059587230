import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const BASE_URL = 'https://stakeplot2.onrender.com'
export const apiSlice = createApi({
    baseQuery : fetchBaseQuery({ baseUrl:BASE_URL}),
    endpoints : builder => ({
        //getpartners
        getpartner : builder.query({
            query:() => '/api/partner'
        }),
        //getpartner details
        getPDetails : builder.query({
            query : (emailAddress) => `/api/partnerdetails/${emailAddress}`
        }),
        //authenticate
        authenticate: builder.query({
            query: (emailAddress) => `/authenticate?emailAddress=${emailAddress}`,
          }),
          //verifypassword
          loginuser: builder.mutation({
            query: ({ emailAddress, password }) => ({
              url: '/api/login',
              method: 'POST',
              body: { emailAddress, password },
            }),
          }),

          //updatepassword
          updateUser: builder.mutation({
            query: (response) => ({
              url: '/updateuser',
              method: 'PUT',
              body: response,
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }),
          }),
          //generateotp
          // generateOTP: builder.query({
          //   query: () => `/api/generateOTP`,
          // }),


        //add partner
        addPartner : builder.mutation({
            query : (credentials) => ({
                url:'/api/partnerdetails',
                method:"POST",
                body:credentials
            })
        }),
        //register mail
        sendMailR: builder.mutation({
          query: ({ emailAddress, FirstName, text, subject }) => ({
            url: '/api/registerMail',
            method: 'POST',
            body: {
              emailAddress,
              FirstName,
              text,
              subject,
            },
          }),
        }),
        //update partner
        updateDetails : builder.mutation({
          query : ({partnerId,details}) => ({
            url:`/api/partnerdetails/update`,
            method:"PUT",
            body: {partnerId,...details},
            headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` },
        })
        }),
        //add offers
        addOffer : builder.mutation({
            query : (initialDetails) => ({
                url : '/api/offer',
                method:"POST",
                body:initialDetails
            })
        }),
        //get offers
        getOffer : builder.query({
          query : (emailAddress) => `/api/offer/${emailAddress}`
        }),
        //get coupons
        getCoupons : builder.query({
          query : (emailAddress) => `/api/coupons/${emailAddress}`
        }),
        //update offer
        updateStatus : builder.mutation({
          query : ({id, isActive}) => ({
            url : '/api/offerupdate',
            method : "PUT",
            body : {id,isActive}
          })
        }),
        //delete offer
        deleteOffer : builder.mutation({
            query : recordid => ({
                url : '/api/offer/delete',
                method:"DELETE",
                body:recordid
            })
        }),
        //mail
        generateOTP: builder.query({
          query: (emailAddress) => ({
            url: '/api/generateOTP',
            params: { emailAddress },
          }),
        }),
        sendMail: builder.mutation({
          query: ({ emailAddress, FirstName, code, subject }) => ({
            url: '/api/registerMail',
            method: 'POST',
            body: {
              emailAddress,
              FirstName,
              text : `Your Password Recovery OTP is ${code}. Verify and recover your password.`,
              subject,
            },
          }),
        }),
        //verify otp
        verifyOTP: builder.mutation({
          query: ({ emailAddress, code }) => ({
            url: '/api/verifyOTP',
            method: 'GET',
            params: { emailAddress, code },
          }),
        }),
        //resetpassword
        resetPassword: builder.mutation({
          query: ({ emailAddress, password }) => ({
            url: '/api/resetpassword',
            method: 'PUT',
            body: { emailAddress, password },
          }),
        }),
        //review
        reviews: builder.mutation({
          query: ({review}) => ({
            url : '/api/reviews',
            method: 'POST',
            body: {review}
          })
        }),
        //enroll
        enrolls : builder.mutation({
          query: ({email}) => ({
            url : '/api/enroll',
            method : 'POST',
            body : {email}
          })
        }),
        //appAccountdeletion
        Accountdeletion : builder.mutation({
          query: ({ email, reason, confirmed }) => ({
            url : '/api/appAccountDeletion',
            method : 'POST',
            body : { email, reason, confirmed },
          })
        })
    })
})


export default apiSlice