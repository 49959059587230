import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { default as api } from '../store/apiSlice';

export default function PartnerRecovery() {
  const [otp, setOtp] = useState('');
  const [resendError, setResendError] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [verifyingOTP, setVerifyingOTP] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { emailAddress } = location.state;
  const [verifyOTP] = api.useVerifyOTPMutation();
  const [verificationError, setVerificationError] = useState(null);
  const [triggerGenerateOTP] = api.useLazyGenerateOTPQuery();
  const [sendMail] = api.useSendMailMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setVerifyingOTP(true);
      const { data, error } = await verifyOTP({ emailAddress: emailAddress, code: otp }).unwrap();
      if (error) {
        setVerificationError('Failed to verify OTP');
        console.error('Failed to verify OTP:', error);
      } else {
        console.log('OTP verified:', data);
        navigate('/resetpassword', { state: { emailAddress: emailAddress } });
      }
    } catch (error) {
      setVerificationError('Failed to verify OTP');
      console.error('Failed to verify OTP:', error);
    } finally {
      setVerifyingOTP(false);
    }
  };

  const handleResendOTP = async () => {
    try {
      toast.info('sending mail..!')
      setResendError(null);
      const { data } = await triggerGenerateOTP(emailAddress);
      if (data) {
        await sendMail({
          emailAddress,
          code: data.code,
          subject: 'Password Recovery OTP'
        }).unwrap();
        console.log('OTP resent:', data);
        setOtpSent(true);
        toast.success('Email sent successfully');
      } else {
        console.error('Failed to generate OTP');
        setResendError('Failed to generate OTP');
      }
    } catch (error) {
      console.error('Failed to generate or send OTP:', error);
      setResendError('Failed to generate or send OTP');
      toast.error('Failed to send email');
    }
  };

  return (
    <div className="container mx-auto max-w-md mt-8">
      <h2 className='text-3xl py-4'>OTP Verification</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="block font-serif text-gray-700" htmlFor="otp">Enter OTP:</label>
          <input
            type="text"
            id="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className="w-full h-[80px] text-2xl font-serif px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <br />
        <button className="bg-[#116a7b] font-serif text-xl w-[40%] h-[60px] text-white p-4" type="submit" disabled={verifyingOTP}>Submit</button>
        {verificationError && <p className="text-red-500 mt-2">{verificationError}</p>}
      </form>
      <div className='flex justify-end'>
        {otpSent ? (
          <button
            className="bg-[#116a7b] font-serif text-center w-[25%] h-[30px] text-white m-4"
            onClick={handleResendOTP}
            disabled={verifyingOTP}
          >
            Resend OTP
          </button>
        ) : (
          <button
            className="bg-[#116a7b] font-serif text-center w-[20%] h-[30px] text-white m-4"
            onClick={handleResendOTP}
            disabled={verifyingOTP}
          >
            Get OTP
          </button>
        )}
      </div>
      {resendError && <p className="text-red-500 mt-2">{resendError}</p>}
    </div>
  );
}
