import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import Section7 from '../section7';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

export default function Breakcost() {
  // Spring Break state variables
  const [destination, setDestination] = useState('Beach'); // Travel destination
  const [accommodationType, setAccommodationType] = useState('Hotel'); // Accommodation type
  const [dailyExpenses, setDailyExpenses] = useState(100); // Daily expenses
  const [entertainmentBudget, setEntertainmentBudget] = useState(500); // Entertainment budget
  const [numberOfDays, setNumberOfDays] = useState(7); // Number of days
  const [travelCost, setTravelCost] = useState(300); // Travel cost
  const [numberOfMembers, setNumberOfMembers] = useState(1); // Number of members

  const [totalCost, setTotalCost] = useState(0); // Total cost of the trip
  const [costPerMember, setCostPerMember] = useState(0); // Cost per member

  useEffect(() => {
    // Calculate total cost
    const accommodationCostPerDay = accommodationType === 'Hotel' ? 1500 * Math.round(numberOfMembers/2) : accommodationType === 'Airbnb' ? 1000 * Math.round(numberOfMembers / 2) : accommodationType === 'Hostel' ? 400 * numberOfMembers : accommodationType === 'Camping' ? 450 * Math.round(numberOfMembers / 2) : 0; // Example costs
    const totalAccommodationCost = accommodationCostPerDay * numberOfDays;
    const totalDailyExpenses = dailyExpenses * numberOfDays;
    const totalTripCost = travelCost + totalAccommodationCost + totalDailyExpenses + entertainmentBudget;

    // Calculate cost per member
    const perMemberCost = totalTripCost / numberOfMembers;

    // Update state with calculated values
    setTotalCost(Math.round(totalTripCost));
    setCostPerMember(Math.round(perMemberCost));
  }, [destination, accommodationType, dailyExpenses, entertainmentBudget, numberOfDays, travelCost, numberOfMembers]);

  // Data for Doughnut chart
  const data = {
    labels: ['Travel Cost', 'Accommodation Cost', 'Daily Expenses', 'Entertainment Budget'],
    datasets: [
      {
        data: [
          travelCost,
          (accommodationType === 'Hotel' ? 1500 * Math.round(numberOfMembers/2) : accommodationType === 'Airbnb' ? 1000 * Math.round(numberOfMembers / 2) : accommodationType === 'Hostel' ? 400 * numberOfMembers : accommodationType === 'Camping' ? 450 * Math.round(numberOfMembers / 2) : 0),
          dailyExpenses * numberOfDays,
          entertainmentBudget,
        ],
        backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };

  return (
    <div>
      <div className='bg-[#ECE5C7]'>
        <div className="flex-shrink-0">
          <h1 className="text-3xl bg-white/40 backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]">Stakeplot</h1>
        </div>
        <div className='text-3xl backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]'>
          <h1>Spring Break Cost Calculator</h1>
        </div>
        <div className="max-w-md p-6 mx-auto my-10 border-4 border-white rounded-3xl">
          {/* Travel destination input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="destination" className="block text-sm font-medium text-[#116a7b]">
              Travel Destination
            </label>
            <select
              id="destination"
              name="destination"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              className="w-full bg-[#C2DEDC] rounded-lg p-2 text-[#116a7b]"
            >
              <option value="Beach">Beach</option>
              <option value="Mountains">Mountains</option>
              <option value="City">City</option>
              <option value="Countryside">Countryside</option>
            </select>
          </div>
          {/* Accommodation type input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="accommodationType" className="block text-sm font-medium text-[#116a7b]">
              Accommodation Type
            </label>
            <select
              id="accommodationType"
              name="accommodationType"
              value={accommodationType}
              onChange={(e) => setAccommodationType(e.target.value)}
              className="w-full bg-[#C2DEDC] rounded-lg p-2 text-[#116a7b]"
            >
              <option value="Hotel">Hotel</option>
              <option value="Hostel">Hostel</option>
              <option value="Airbnb">Airbnb</option>
              <option value="Camping">Camping</option>
            </select>
          </div>
          {/* Daily expenses input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="dailyExpenses" className="block text-sm font-medium text-[#116a7b]">
              Daily Expenses
            </label>
            <input
              type="range"
              id="dailyExpenses"
              name="dailyExpenses"
              min="50"
              max="500"
              step="10"
              value={dailyExpenses}
              onChange={(e) => setDailyExpenses(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${dailyExpenses}`}</div>
          </div>
          {/* Entertainment budget input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="entertainmentBudget" className="block text-sm font-medium text-[#116a7b]">
              Entertainment Budget
            </label>
            <input
              type="range"
              id="entertainmentBudget"
              name="entertainmentBudget"
              min="100"
              max="2000"
              step="50"
              value={entertainmentBudget}
              onChange={(e) => setEntertainmentBudget(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${entertainmentBudget}`}</div>
          </div>
          {/* Number of days input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="numberOfDays" className="block text-sm font-medium text-[#116a7b]">
              Number of Days
            </label>
            <input
              type="range"
              id="numberOfDays"
              name="numberOfDays"
              min="1"
              max="30"
              step="1"
              value={numberOfDays}
              onChange={(e) => setNumberOfDays(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${numberOfDays} days`}</div>
          </div>
          {/* Travel cost input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="travelCost" className="block text-sm font-medium text-[#116a7b]">
              Travel Cost
            </label>
            <input
              type="range"
              id="travelCost"
              name="travelCost"
              min="100"
              max="5000"
              step="100"
              value={travelCost}
              onChange={(e) => setTravelCost(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${travelCost}`}</div>
          </div>
          {/* Number of members input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="numberOfMembers" className="block text-sm font-medium text-[#116a7b]">
              Number of Members
            </label>
            <input
                type="number"
                id="numberOfMembers"
                name="numberOfMembers"
                min="1"
                max="10"
                value={numberOfMembers}
                onChange={(e) => setNumberOfMembers(parseInt(e.target.value))}
                className="w-full bg-[#C2DEDC] rounded-lg p-2 text-[#116a7b]"
            />
            </div>
            {/* Display Spring Break Cost details */}
            <div className="mt-8">
              <div className="text-xl text-[#116a7b] font-semibold">Spring Break Cost Details:</div>
              <div className="text-[#116a7b]">Total Cost of the Trip: ₹{totalCost}</div>
              <div className="text-[#116a7b]">Cost Per Member: ₹{costPerMember}</div>
              <div className="text-[#116a7b]">Number of Members: {numberOfMembers}</div>
            </div>
            {/* Doughnut chart to visualize cost breakdown */}
            <div className="m-2 w-[350px] flex justify-center">
              <Doughnut data={data} options={options} />
            </div>
           </div>
           <div className='mx-4'>
          <h2 className='text-2xl font-bold'>Vacation/Trip Cost Calculator</h2>
          <p>The Spring Break Cost Calculator helps you estimate and plan your expenses for a spring break trip based on various factors such as travel destination, accommodation type, daily expenses, entertainment budget, number of days, travel cost, and the number of members in your group.</p>
          <h1 className='text-lg py-2 font-bold'>How to Use the Calculator:</h1>
          <h2 className='font-bold'>Travel Destination:</h2>
          <p>Select the desired travel destination from the provided options (Beach, Mountains, City, Countryside).</p>
          <h2 className='font-bold'>Accommodation Type:</h2>
          <p>Choose the type of accommodation you prefer for your trip (Hotel, Hostel, Airbnb, Camping).</p>
          <h2 className='font-bold'>Daily Expenses:</h2>
          <p>Adjust the slider to set the estimated daily expenses per person during the trip.</p>
          <h2 className='font-bold'>Entertainment Budget:</h2>
          <p>Set the budget allocated for entertainment activities during the trip.</p>
          <h2 className='font-bold'>Number of Days:</h2>
          <p>Use the slider to specify the duration of your spring break trip in days.</p>
          <h2 className='font-bold'>Travel Cost:</h2>
          <p>Adjust the slider to set the estimated travel cost for transportation to the destination.</p>
          <h2 className='font-bold'>Number of Members:</h2>
          <p>Enter the total number of individuals participating in the trip.</p>
          <h2 className='text-lg py-2 font-bold'>How It Works:</h2>
          <div className='border border-black rounded-lg p-2'>
          <p>The calculator incorporates predefined costs.</p>
          <h2 className='font-bold'>Total Trip Cost Calculation:</h2>
          <p>The calculator computes the total cost of the trip, including travel expenses, accommodation costs, daily expenses, and entertainment budget.</p>
          <h2 className='font-bold'>Cost Per Member Calculation:</h2>
          <p>It calculates the average cost per member by dividing the total trip cost by the number of members in the group.</p>
          <h2 className='font-bold'>Cost Breakdown Visualization:</h2>
          <p>The cost breakdown is visualized using a doughnut chart, providing a clear breakdown of expenses into categories like travel cost, accommodation cost, daily expenses, and entertainment budget.</p>
          </div>
          <p>By utilizing this tool, you can effectively plan and manage your expenses for an enjoyable and memorable spring break trip.</p>
          </div>
        </div>
        <Section7></Section7>
      </div>
  );
}
            
             
