// ResetPasswordForm.js

import React, { useState } from 'react';
import { useLocation,useNavigate} from 'react-router-dom';
import { default as api } from '../store/apiSlice';

export default function ResetPasswordForm() {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [sendMail] = api.useSendMailRMutation()
  const [error, setError] = useState(null);
  const location = useLocation();
  const emailAddress = location.state?.emailAddress;

  const [resetPassword] = api.useResetPasswordMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const response = await resetPassword({ emailAddress, password });
      if (response.error) {
        setError('Failed to reset password');
      } else if (response.data && response.data.error) {
        setError(response.data.error);
      } else {
        console.log('Password reset successful');
        // Reset form fields
        setPassword('');
        setConfirmPassword('');
        navigate('/partner-login')
        await sendMail({
            emailAddress,
            text: 'Your password has been successfully reset..!',
            subject: 'Password Recovery',
          }).unwrap();
      }
    } catch (error) {
      console.error('Failed to reset password:', error);
      setError('Failed to reset password');
    }
  };

  return (
    <div className="container mx-auto max-w-md mt-8">
      <h2 className='text-3xl py-4'>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="password" className="block font-serif text-gray-700">New Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full h-[80px] text-2xl font-serif px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block font-serif text-gray-700">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="w-full h-[80px] text-2xl font-serif px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <button
          type="submit"
          className="bg-[#116a7b] font-serif text-xl w-[40%] h-[60px] text-white p-4"
        >
          Reset Password
        </button>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </form>
    </div>
  );
}
