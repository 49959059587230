import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import {default as api} from '../store/apiSlice'
import { toast } from 'react-toastify';
import insta from '/Users/adnan/Desktop/w2/client/src/images/tt-removebg-preview.png'
import x from  '/Users/adnan/Desktop/w2/client/src/images/images-removebg-preview.png'


export default function Partner2() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
}, []);
  const [formData, setFormData] = useState({
      FirstName: '',
      LastName: '',
      phoneNumber: '',
      emailAddress: '',
      category: '',
      years: '',
      brandName: '',
      instagramLink: '',
      websiteLink: '',
      competitors:'',
      maxfulfilledorders:'',
      password:'',
      confirm:''
  });

  const [addPartner] = api.useAddPartnerMutation()
  const [sendMail] = api.useSendMailRMutation()
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // If the input is file type, get the first file from the list
    const file = e.target.type === 'file' ? files[0] : null;

    setFormData({
      ...formData,
      [name]: file || value // Use file if it's a file input, otherwise use value
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    if(!formData) return {}
    if (formData.password !== formData.confirm) {
      alert("Passwords do not match");
      return; 
    }

    const passwordRegex = /^(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
  if (!passwordRegex.test(formData.password)) {
    toast.error("Password must contain 8 characters including at least one special character and one digit.");
    return;
  }

    setIsLoading(true);

    try {
      await addPartner(formData).unwrap();
      console.log(formData);

      await sendMail({
        emailAddress: formData.emailAddress,
        FirstName: formData.FirstName,
        text: 'Thank you for joining as a partner! Our team will process your request and activate your account within the next 24 hours. Once your account is activated, you will receive an email notification, and you can then log in and start using stakeplot.',
        subject: 'Welcome to Stakeplot',
      }).unwrap();
      toast.success('Form submitted successfully!');
      setFormData({
        FirstName: '',
        LastName: '',
        phoneNumber: '',
        emailAddress: '',
        category: '',
        years: '',
        brandName: '',
        instagramLink: '',
        websiteLink: '',
        competitors: '',
        maxfulfilledorders: '',
        password: '',
        confirm:''
      });
    } catch (error) {
      if (error.status === 400) {
        const errorMessage = error.data.message;
        if (errorMessage.includes('Email address already exists')) {
          toast.error('Email address already exists')
        } else if (errorMessage.includes('Phone number already exists')) {
          toast.error('Phone number already exists');
        }
      } else {
        alert('An error occurred');
        console.error('Error:', error);
      }
    } finally {
      setIsLoading(false); // Ensure isLoading is set to false after form submission
    }
  };

  return (
    <div>
    <div className="container mx-auto max-w-lg md:max-w-2xl mt-8">
      <div className="container  mx-auto flex justify-between items-center py-2">
        <div className='border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white '>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <Link to='/'>
          <div className="drop-main">
          <div className="S animate-ease">S</div>
            <div className="T animate-ease">t</div>
            <div className="A animate-ease">a</div>
            <div className="K animate-ease">k</div>
            <div className="E animate-ease">e</div>
            <div className="P animate-ease">p</div>
            <div className="L animate-ease">l</div>
            <div className="O animate-ease">o</div>
            <div className="T animate-ease">t</div>
          </div>
          </Link>
          </div>
        </div>
        </div>
        <Link to='/partner-login'>
        <div className='flex justify-end text-lg p-4 border border-gray-400 hover:bg-black hover:text-white'>
        Check in
        </div>
      </Link>
      </div>
      <h2 className="text-3xl font-sans mb-4">Partner Form</h2>
      <h1 className='text-2xl font-serif m-2'>Thank you for expressing your interest in forming a partnership with us.</h1>
      <form onSubmit={handleSubmit}>
        <div className=''>
          <div className='grid grid-cols-2'>
        <div className="mb-4 p-1">
          <input
            type="text"
            required
            name="FirstName"
            value={formData.FirstName}
            onChange={handleChange}
            placeholder="First Name"
            className="w-full h-[80px] text-2xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4 p-1">
          <input
            type="text"
            required
            name="LastName"
            value={formData.LastName}
            onChange={handleChange}
            placeholder="Last Name"
            className="w-full h-[80px] text-2xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        </div>
        <div className='grid md:grid-cols-2 m-1'>
        <div className="mb-4 p-1">
        <label htmlFor="phoneNumber" className="block text-md font-medium text-gray-700"><h1>Phone Number</h1></label>
        <div className='flex'>
            <span className="inline-flex items-center px-3 border border-dashed border-black text-sm text-gray-900 rounded-e-0 border-e-0 rounded-s-md  ">
              <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
              </svg>
            </span>
          <input
            type="text"
            id='phoneNumber'
            name="phoneNumber"
            required
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
            className="w-full h-[80px] text-2xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"/>
          </div>
        </div>
        <div className="mb-4 p-1">
        <label htmlFor="email" className="block text-md font-medium text-gray-700">Office Email</label>
        <div className='flex'>
            <span className="inline-flex items-center px-3 border border-dashed border-black text-sm text-gray-900 rounded-e-0 border-e-0 rounded-s-md  ">
            <svg className='w-6 h-6' viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
            	<path d="m0 0h8v6h-8zm.75 .75v4.5h6.5v-4.5zM0 0l4 3 4-3v1l-4 3-4-3z"/>
            </svg>
            </span>
            <input
              type="email"
              id="email"
              required
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleChange}
              placeholder="Email Address"
              className="w-full h-[80px] text-2xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
        </div>
        </div>
        </div>
        </div>
        <div className="border-t-2 border-gray-300 my-4"></div>
        <h2 className='text-lg font-sans'>Let us know about your brand better</h2>
        <div className="mb-4">
        <label htmlFor="category" className="sr-only">Underline select</label>
          <select
            name="category"
            id='category'
            required
            value={formData.category}
            onChange={handleChange}
            className="block py-2.5 px-2 w-full h-[60px] text-xl text-black bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-black dark:border-black focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="">Select Category</option>
            <option value="fashion&apparel">Fashion & Apparel</option>
            <option value="travel&hospitality">Travel & Hospitality</option>
            <option value="technology&electronics">Technology & Electronics</option>
            <option value="Entertainment&Media">Entertainment & Media</option>
            <option value="Beauty&Cosmetics">Beauty & Cosmetics</option>
            <option value="Home&Lifestyle">Home & Lifestyle</option>
            <option value="food">Food</option>
            <option value="Education&Learning">Education & Learning</option>
            <option value="Sports&recreation">Sports & Recreation</option>
            <option value="PetCare">Pet Care</option>
          </select>
        </div>
        <div className="mb-4">
          <select
            name="years"
            required
            value={formData.years}
            onChange={handleChange}
            className="block py-2.5 px-2 w-full h-[60px] text-xl text-black bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-black dark:border-black focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="">Operating years</option>
            <option value="less than 1 year">less than 1 year</option>
            <option value="2-3 years">2-3 years</option>
            <option value="4-5 years">4-5 years</option>
            <option value="more than 5 years">more than 5 years</option>
          </select>
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="brandName"
            required
            value={formData.brandName}
            onChange={handleChange}
            placeholder="Brand Name"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="instagramLink"
            required
            value={formData.instagramLink}
            onChange={handleChange}
            placeholder="Instagram Link"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="websiteLink"
            required
            value={formData.websiteLink}
            onChange={handleChange}
            placeholder="Website Link"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <h1 className='text-lg font-sans py-2'>Numbers tell the true story, and we couldn't agree more..</h1>
        <div className="mb-4">
          <input
            type="text"
            name="competitors"
            required
            value={formData.competitors}
            onChange={handleChange}
            placeholder="Competitors in your space, mention with a ' , ' in between"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <input
            type="Number"
            name="maxfulfilledorders"
            required
            value={formData.maxfulfilledorders}
            onChange={handleChange}
            placeholder="Maximum fulfilled orders in a day"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            name="password"
            required
            value={formData.password}
            onChange={handleChange}
            placeholder="password"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            name="confirm"
            required
            value={formData.confirm}
            onChange={handleChange}
            placeholder="confirm password"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className='flex justify-center items-center py-2'>
        <button type="submit" className="bg-[#116a7b] font-sans text-xl w-[40%] h-[70px] text-white p-4">
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
        </div>
      </form>
      
    </div>
    <div className='bg-[#116a7b] p-4 rounded-t-md'>
    <h1 className='text-2xl text-white'>Connect with us:</h1>
        <h1 className='text-white'>stakeplot@gmail.com</h1>
        <div className=' py-3 flex gap-4'>
          <Link to='https://www.instagram.com/stakeplot?igsh=MWpuZ21ncjg4N3ho&utm_source=qr' target='_blank'>
          <img className='w-[35px] h-[35px] bg-white border rounded-full' src={insta} alt='insta'></img>
          </Link>
          <Link to='https://x.com/stakeplot?s=21' target='_blank'>
          <img className='w-[35px] h-[35px] bg-white border rounded-full' src={x} alt='x'></img>
          </Link>
        </div>
        <div className='flex justify-center items-center'>
        <h1 className='p-8 text-6xl text-white opacity-40'>Stakeplot</h1>
      </div>
      </div>
    </div>
  );
}
