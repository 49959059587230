import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import Section7 from '../section7';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

export default function RentvsBuy() {
  // Rent vs Buy state variables
  const [homePrice, setHomePrice] = useState(5000000); // Home price
  const [downPayment, setDownPayment] = useState(20); // Down payment as percentage
  const [loanInterestRate, setLoanInterestRate] = useState(7); // Annual loan interest rate
  const [loanTenure, setLoanTenure] = useState(240); // Loan tenure in months
  const [propertyTaxRate, setPropertyTaxRate] = useState(1); // Annual property tax rate as percentage
  const [maintenanceCost, setMaintenanceCost] = useState(1); // Annual maintenance cost as percentage of home price
  const [homeAppreciationRate, setHomeAppreciationRate] = useState(3); // Annual home appreciation rate
  const [rent, setRent] = useState(20000); // Monthly rent
  const [rentIncreaseRate, setRentIncreaseRate] = useState(5); // Annual rent increase rate

  const [totalRentCost, setTotalRentCost] = useState(0); // Total cost of renting
  const [totalBuyCost, setTotalBuyCost] = useState(0); // Total cost of buying

  useEffect(() => {
    // Calculate monthly loan interest rate
    const monthlyInterestRate = loanInterestRate / 12 / 100;
    const downPaymentAmount = (downPayment / 100) * homePrice;
    const loanAmount = homePrice - downPaymentAmount;

    // Calculate EMI
    const emi = loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTenure) / (Math.pow(1 + monthlyInterestRate, loanTenure) - 1);
    const totalLoanCost = emi * loanTenure;

    // Calculate total property tax
    const totalPropertyTax = (propertyTaxRate / 100) * homePrice * (loanTenure / 12);

    // Calculate total maintenance cost
    const totalMaintenanceCost = (maintenanceCost / 100) * homePrice * (loanTenure / 12);

    // Calculate appreciation
    const appreciatedValue = homePrice * Math.pow(1 + (homeAppreciationRate / 100), loanTenure / 12);

    // Calculate total buying cost
    const totalBuyCost = downPaymentAmount + totalLoanCost + totalPropertyTax + totalMaintenanceCost - appreciatedValue;

    // Calculate total rent cost
    let totalRent = 0;
    let currentRent = rent;
    for (let i = 0; i < loanTenure / 12; i++) {
      totalRent += currentRent * 12;
      currentRent *= 1 + (rentIncreaseRate / 100);
    }

    // Update state with calculated values
    setTotalBuyCost(Math.round(totalBuyCost));
    setTotalRentCost(Math.round(totalRent));
  }, [
    homePrice,
    downPayment,
    loanInterestRate,
    loanTenure,
    propertyTaxRate,
    maintenanceCost,
    homeAppreciationRate,
    rent,
    rentIncreaseRate,
  ]);

  // Data for Doughnut chart
  const data = {
    labels: ['Total Buy Cost', 'Total Rent Cost'],
    datasets: [
      {
        data: [totalBuyCost, totalRentCost],
        backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };

  return (
    <div>
      <div className='bg-[#ECE5C7]'>
        <div className="flex-shrink-0">
          <h1 className="text-3xl bg-white/40 backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]">Stakeplot</h1>
        </div>
        <div className='text-3xl backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]'>
          <h1>Rent vs Buy Calculator</h1>
        </div>
        <div className="max-w-md p-6 mx-auto my-10 border-4 border-white rounded-3xl">
          {/* Home price input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="homePrice" className="block text-sm font-medium text-[#116a7b]">
              Home Price
            </label>
            <input
              type="range"
              id="homePrice"
              name="homePrice"
              min="2000000"
              max="100000000"
              step="200000"
              value={homePrice}
              onChange={(e) => setHomePrice(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${homePrice}`}</div>
          </div>
          {/* Down payment input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="downPayment" className="block text-sm font-medium text-[#116a7b]">
              Down Payment (%)
            </label>
            <input
              type="range"
              id="downPayment"
              name="downPayment"
              min="0"
              max="100"
              step="1"
              value={downPayment}
              onChange={(e) => setDownPayment(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${downPayment}%`}</div>
          </div>
          {/* Loan interest rate input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="loanInterestRate" className="block text-sm font-medium text-[#116a7b]">
              Loan Interest Rate (%)
            </label>
            <input
              type="range"
              id="loanInterestRate"
              name="loanInterestRate"
              min="1"
              max="20"
              step="0.1"
              value={loanInterestRate}
              onChange={(e) => setLoanInterestRate(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${loanInterestRate}%`}</div>
          </div>
          {/* Loan tenure input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="loanTenure" className="block text-sm font-medium text-[#116a7b]">
              Loan Tenure (Months)
            </label>
            <input
              type="range"
              id="loanTenure"
              name="loanTenure"
              min="12"
              max="360"
              step="1"
              value={loanTenure}
              onChange={(e) => setLoanTenure(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${loanTenure} months`}</div>
          </div>
          {/* Property tax rate input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="propertyTaxRate" className="block text-sm font-medium text-[#116a7b]">
              Property Tax Rate (%)
            </label>
            <input
              type="range"
              id="propertyTaxRate"
              name="propertyTaxRate"
              min="0"
              max="5"
              step="0.1"
              value={propertyTaxRate}
              onChange={(e) => setPropertyTaxRate(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${propertyTaxRate}%`}</div>
          </div>
          {/* Maintenance cost input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="maintenanceCost" className="block text-sm font-medium text-[#116a7b]">
              Maintenance Cost (% per year)
            </label>
            <input
              type="range"
              id="maintenanceCost"
              name="maintenanceCost"
              min="0"
              max="10"
              step="0.1"
              value={maintenanceCost}
              onChange={(e) => setMaintenanceCost(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${maintenanceCost}%`}</div>
          </div>
          {/* Home appreciation rate input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="homeAppreciationRate" className="block text-sm font-medium text-[#116a7b]">
              Home Appreciation Rate (% per year)
            </label>
            <input
              type="range"
              id="homeAppreciationRate"
              name="homeAppreciationRate"
              min="0"
              max="20"
              step="0.1"
              value={homeAppreciationRate}
              onChange={(e) => setHomeAppreciationRate(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${homeAppreciationRate}%`}</div>
          </div>
          {/* Rent input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="rent" className="block text-sm font-medium text-[#116a7b]">
              Monthly Rent
            </label>
            <input
              type="range"
              id="rent"
              name="rent"
              min="5000"
              max="100000"
              step="500"
              value={rent}
              onChange={(e) => setRent(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${rent}`}</div>
          </div>
          {/* Rent increase rate input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="rentIncreaseRate" className="block text-sm font-medium text-[#116a7b]">
              Rent Increase Rate (% per year)
            </label>
            <input
              type="range"
              id="rentIncreaseRate"
              name="rentIncreaseRate"
              min="0"
              max="10"
              step="0.1"
              value={rentIncreaseRate}
              onChange={(e) => setRentIncreaseRate(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${rentIncreaseRate}%`}</div>
          </div>
          {/* Display Rent vs Buy details */}
          <div className="mt-8">
            <div className="text-xl text-[#116a7b] font-semibold">Rent vs Buy Details:</div>
            <div className="text-[#116a7b]">Total Cost of Renting: ₹{totalRentCost}</div>
            <div className="text-[#116a7b]">Total Cost of Buying: ₹{totalBuyCost}</div>
          </div>
          {/* Doughnut chart to visualize rent vs buy cost */}
          <div className="m-2 w-[350px] flex justify-center">
            <Doughnut data={data} options={options} />
          </div>
        </div>
        <div className='mx-4'>
          <h2 className='text-2xl font-bold'>Rent vs Buy Calculator</h2>
          <p>The Rent vs Buy Calculator helps you decide whether it’s financially better to rent or buy a home based on various input parameters. This tool calculates and compares the total costs involved in both scenarios over a specified period.</p>
          <h1 className='text-lg py-2 font-bold'>How to Use the Calculator:</h1>
          <h2 className='underline'>Home Price:</h2>
          <p>Adjust the slider to set the price of the home you are considering to buy.</p>
          <h2 className='underline'>Down Payment (%):</h2>
          <p>Use the slider to set the percentage of the home price you plan to pay upfront as a down payment.</p>
          <h2 className='underline'>Loan Interest Rate (%):</h2>
          <p>Set the annual interest rate for the loan using the slider.</p>
          <h2 className='underline'>Loan Tenure (Months):</h2>
          <p>Adjust the slider to set the loan tenure in months (1-360 months).</p>
          <h2 className='underline'>Property Tax Rate (%):</h2>
          <p>Use the slider to set the annual property tax rate as a percentage of the home price.</p>
          <h2 className='underline'>Maintenance Cost (% per year):</h2>
          <p>Set the annual maintenance cost as a percentage of the home price using the slider.</p>
          <h2 className='underline'>Home Appreciation Rate (% per year):</h2>
          <p>Adjust the slider to set the expected annual appreciation rate of the home’s value.</p>
          <h2 className='underline'>Monthly Rent:</h2>
          <p>Use the slider to set the current monthly rent.</p>
          <h2 className='underline'>Rent Increase Rate (% per year):</h2>
          <p>Adjust the slider to set the annual rent increase rate.</p>
          <h2 className='text-lg py-2 font-bold'>How It Works:</h2>
          <div className='border border-black rounded-lg p-2'>
          <h2 className='underline'>EMI Calculation:</h2>
          <p>The calculator determines the Equated Monthly Installment (EMI) based on the home price, down payment, loan interest rate, and loan tenure.</p>
          <h2 className='underline'>Total Buy Cost Calculation:</h2>
          <p>This includes the down payment, total loan cost (EMI x loan tenure), property tax, and maintenance cost over the loan tenure.</p>
          <p>The appreciated value of the home over the loan tenure is subtracted from the total buy cost to account for the potential increase in home value.</p>
          <h2 className='underline'>Total Rent Cost Calculation:</h2>
          <p>The total rent paid over the loan tenure is calculated by accounting for the initial rent and the annual rent increase rate.</p>
          <h2 className='underline'>Comparison:</h2>
          <p>The calculator compares the total cost of buying and renting over the specified period.</p>
          <p>The results are displayed in a doughnut chart, visually representing the costs of both options.</p>
          </div>
          <h2>By comparing these costs, you can make an informed decision on whether to rent or buy a home based on your financial situation and market conditions.</h2>
        </div>
      </div>
      <Section7></Section7>
    </div>
  );
}
