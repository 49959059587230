import React, { useEffect, useRef } from 'react';
import '/Users/adnan/Desktop/w2/client/src/App.css';
import budget from '/Users/adnan/Desktop/w2/client/src/images/images-2/4-budgets.svg'
import insights from '/Users/adnan/Desktop/w2/client/src/images/images-2/4-insights.svg'
import schedule from '/Users/adnan/Desktop/w2/client/src/images/images-2/4-schedule.svg'
import friends from '/Users/adnan/Desktop/w2/client/src/images/images-2/4-friends.svg'
import room from '/Users/adnan/Desktop/w2/client/src/images/images-2/4-room.svg'
import track from '/Users/adnan/Desktop/w2/client/src/images/images-2/4-track.svg'
import debts from '/Users/adnan/Desktop/w2/client/src/images/images-2/4-debts.svg'
import community from '/Users/adnan/Desktop/w2/client/src/images/images-2/4-community.svg'


const cardData = [
  {
    id: 1,
    title: "Space",
    description: "Explore, Connect, Empower: Unraveling Your Financial Thoughts",
    imageUrl: schedule
  },
  {
    id: 2,
    title: "Insights",
    description: "Unlock Great Insights for Smarter Financial Choices.",
    imageUrl: insights
  },
  {
    id: 3,
    title: "Budgets",
    description: "Empower Your Finances: Set Your Own Budgets, Your Way",
    imageUrl: budget
  },
  {
    id: 4,
    title: "Debts management",
    description: "Take Control: Plan Your Debts, Secure Your Future",
    imageUrl: debts
  },
  {
    id: 5,
    title: "Flatmates",
    description: "Simplify Shared Living Expenses Effortlessly",
    imageUrl: room
  },
  {
    id: 6,
    title: "Hassle-Free",
    description: "Seamlessly Track Your Expenses, Hassle-Free.",
    imageUrl: track
  },
  {
    id: 7,
    title: "Community",
    description: "Welcome to Our Community: Where Fun Meets Financial Wisdom",
    imageUrl: community
  },
  {
    id: 8,
    title: "Bills",
    description: "Split Bills, Share Joy: Effortless Finance with Friends",
    imageUrl: friends
  }
];

const Section5 = () => {
  const carouselRef = useRef(null);

  const scroll = (direction) => {
    if (carouselRef.current) {
      const { scrollLeft, clientWidth } = carouselRef.current;
      const scrollTo = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
      carouselRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      { threshold: 0.8 }
    );

    cards.forEach(card => {
      observer.observe(card);
    });

    return () => {
      cards.forEach(card => {
        observer.unobserve(card);
      });
    };
  }, []);

  return (
    <div className="relative overflow-hidden m-2 mt-12">
      <button className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-gray-800 text-white p-3 mx-2 rounded-full" onClick={() => scroll('left')}>‹</button>
      <div ref={carouselRef} className="flex overflow-x-auto scroll-smooth scrollbar-hide ">
        {cardData.map((card) => (
          <div key={card.id} className="card flex-none w-44 h-80 m-4 bg-white shadow-lg rounded-lg transform transition-transform duration-300">
            <img src={card.imageUrl} alt={card.title} className="w-full h-40  rounded-t-lg" />
            <div className="p-4">
              <h3 className="text-xl font-bold mb-2">{card.title}</h3>
              <p className="text-gray-700">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
      <button className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-gray-800 text-white p-3 mx-2 rounded-full" onClick={() => scroll('right')}>›</button>
    </div>
  );
};

export default Section5;
