import React from 'react'

export default function PartnerPerformance() {
  return (
    <div>
      <h1>Dashboard</h1>
      <div className='m-2 grid grid-cols-2 sm:grid-cols-3 gap-3'>
        <div className=' h-[150px] bg-slate-500 flex justify-center items-center rounded-lg'>
            <div className='flex items-center space-x-2'>
            <h1 className='text-xl'>views :</h1>
            <p className='text-3xl'>count</p>
            </div>
        </div>
        <div className='h-[150px] bg-slate-500 flex justify-center items-center rounded-lg'>
        <div className='flex items-center space-x-2'>
            <h1 className='text-xl'>Impressions :</h1>
            <p className='text-3xl'>count</p>
            </div>
        </div>
        <div className='h-[150px] bg-slate-500 flex justify-center items-center rounded-lg'>
        <div className='flex items-center space-x-2'>
            <h1 className='text-xl'>Leads :</h1>
            <p className='text-3xl'>count</p>
            </div>
        </div>
      </div>
      <div className='bg-gray-400 h-[1px]'></div>
    </div>
  )
}
