import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Section7 from '../section7';
import blogs from './blogs'; // Import the blogs array

export default function Blogpage() {
    const { title } = useParams();
    const blog = blogs.find(blog => blog.title === title.replace(/-/g, ' '));
    useEffect(() => {
      window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);
  
    return (
      <div className='bg-[#ece5c7]'>
        <div className='border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2'>
        <div className="text-[#116a7b] text-3xl ">
            <h1>Stakeplot</h1>
        </div>
        </div>
        {blog && (
          <div>
            <h1 className='m-2 text-2xl font-serif text-center text-black'>{blog.title}</h1>
            <div className='flex justify-center mt-6'>
            <img className='w-[75%] md:w-[55%] lg:w-[40%] h-[350px] lg:h-[400px]' src={blog.image} alt={blog.title} />
            </div>
            <div className='flex justify-center items-center'>
              <p className='mt-4 text-lg mx-[12%] w-full'>{blog.content}</p>
            </div>
            <div >
            {blog.subheadings && (
            <div>
              {blog.subheadings.map((subheading, index) => (
                <div key={index} className='mt-2 mb-6'>
                  <div className='flex justify-center items-center'>
                  <h2 className='text-xl font-sans mx-[12%] font-bold w-full'>{subheading.title}</h2>
                  </div>
                  <div className='flex justify-center items-center'>
                  <p className='text-lg mx-[12%] w-full'>{subheading.content}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
            </div>
          </div>
        )}
        <Section7></Section7>
      </div>
    );
}
