import React from 'react'
import { Link } from 'react-router-dom'
import insta from '/Users/adnan/Desktop/w2/client/src/images/tt-removebg-preview.png'
import x from  '/Users/adnan/Desktop/w2/client/src/images/images-removebg-preview.png'

export default function Section7() {
  return (
    <div className='bg-[#2F4858] rounded-t-xl'>
      <div className='grid grid-cols-1 md:grid-cols-2 p-8'>
      <div>
        <h1 className='text-white m-3 text-lg'>StakePlot, where managing your money becomes an exciting adventure! Track spendings, earnings, and stick to budgets effortlessly while connecting with a vibrant community of financial enthusiasts. Say hello to smart money management and join the financial revolution today with StakePlot! 🚀💸</h1>
        <h1 className='text-2xl text-white px-4'>Connect with us:</h1>
        <div className='px-4 py-3 flex gap-4'>
          <Link to='https://www.instagram.com/stakeplot?igsh=MWpuZ21ncjg4N3ho&utm_source=qr'>
          <img className='w-[35px] cursor-pointer h-[35px] bg-white border rounded-full' src={insta} alt='insta'></img>
          </Link>
          <Link to='https://x.com/stakeplot?s=21'>
          <img className='w-[35px] h-[35px] bg-white border rounded-full' src={x} alt='x'></img>
          </Link>
        </div>
        <h1 className='text-white p-4'>stakeplot@gmail.com</h1>
      </div>
      <div className='m-4'>
      <h1 className='text-2xl text-white'>Quick Links</h1>
      <Link className='text-white text-lg' to='/partner-up'>Partner-up</Link>
      <br />
      <Link className='text-white text-lg' to='/partner-login'>Login</Link>
      <br />
      <Link className='text-white text-lg' to='/privacy-policy'>Privacy policy</Link>
      </div>      
      </div>
      <div className='flex justify-center items-center'>
        <h1 className='p-8 text-6xl text-white opacity-40'>Stakeplot</h1>
      </div>
    </div>
  )
}
