import React, { useState, useEffect } from 'react';
import { default as api } from '../store/apiSlice';
import { toast } from 'react-toastify';
import {jwtDecode} from 'jwt-decode'; // Import jwtDecode directly

export default function PartnerO() {
  const [formData, setFormData] = useState({
    emailAddress: '', 
    productName: '',
    productLink:'',
    actualPrice: '',
    leastPrice: '',
    stage1price: '',
    stage2price: '',
    stage3price: '',
    numberofitems: '',
    productImage: ''
  });

  const [previewImage, setPreviewImage] = useState(null); 

  const token = localStorage.getItem('token'); // Retrieve token from local storage

  useEffect(() => {
    if (token) {
      // Decode token to extract email address
      const decodedToken = jwtDecode(token); // Use jwtDecode
      setFormData(prevState => ({
        ...prevState,
        emailAddress: decodedToken.emailAddress // Set email address from decoded token
      }));
    }
  }, [token]);

  useEffect(() => {
    const actualPrice = parseFloat(formData.actualPrice);
    const leastPrice = parseFloat(formData.leastPrice);

    if (!isNaN(actualPrice) && !isNaN(leastPrice) && actualPrice >= leastPrice) {
      const difference = actualPrice - leastPrice;
      const stage1Price = actualPrice - difference * 0.30;
      const stage2Price = actualPrice - difference * 0.60;

      setFormData(prevState => ({
        ...prevState,
        stage1price: Math.round(stage1Price),
        stage2price: Math.round(stage2Price),
        stage3price: Math.round(leastPrice)
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        stage1price: '',
        stage2price: '',
        stage3price: ''
      }));
    }
  }, [formData.actualPrice, formData.leastPrice]);

  const [addOffer] = api.useAddOfferMutation();

  const handleChange = async (e) => {
    const { name, value, files } = e.target;

    if (name === 'productImage') {
      const file = files[0];
      const base64 = await convertToBase64(file);
      setFormData({
        ...formData,
        [name]: base64
      });
      setPreviewImage(base64);
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting Data:", formData); // Log form data

    if (!formData) return {};
    
    try {
      const response = await addOffer(formData).unwrap();
      console.log("Submitted Data:", response);
      toast.success("Product details submitted successfully!");
      setFormData({
        emailAddress: formData.emailAddress,
        productName: '',
        productLink:'',
        actualPrice: '',
        leastPrice: '',
        stage1price: '',
        stage2price: '',
        stage3price: '',
        numberofitems: '',
        productImage: ''
      });
      setPreviewImage(null);
      document.getElementById('productImage').value = '';
    } catch (error) {
      console.error("Submission Error:", error); // Log the error
      toast.error(`Error submitting product details: ${error.message}`); // Display error message
    }
  };

  return (
    <div className="container mx-auto max-w-md mt-8">
      <h2 className="text-2xl font-bold mb-4">Enter Product Details</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="hidden" 
          name="emailAddress"
          value={formData.emailAddress} 
        />

        <div className="mb-4">
          <label htmlFor="productName" className="block text-2xl my-2 font-medium text-gray-700">Product Name</label>
          <input
            type="text"
            name="productName"
            required
            id="productName"
            value={formData.productName}
            onChange={handleChange}
            placeholder="Enter product name"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="productLink" className="block text-2xl my-2 font-medium text-gray-700">Product Link</label>
          <input
            type="text"
            name="productLink"
            required
            id="productLink"
            value={formData.productLink}
            onChange={handleChange}
            placeholder="Enter product Link"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="actualPrice" className="block text-2xl my-2 font-medium text-gray-700">Actual Price</label>
          <input
            type="number"
            name="actualPrice"
            required
            id="actualPrice"
            value={formData.actualPrice}
            onChange={handleChange}
            placeholder="Enter actual price"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="leastPrice" className="block text-2xl my-2 font-medium text-gray-700">Least Price</label>
          <input
            type="number"
            name="leastPrice"
            id="leastPrice"
            required
            value={formData.leastPrice}
            onChange={handleChange}
            placeholder="Enter least price"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <h1>These prices are divided for faster sales and easy sales</h1>
        <div className='border border-black p-2'>
        <div className="mb-4">
          <label htmlFor="stage1price" className="block my-2 font-medium text-gray-700">Stage 1 Price</label>
          <input
            type="number"
            name="stage1price"
            id="stage1price"
            required
            value={formData.stage1price}
            readOnly
            placeholder="Stage 1 Price"
            className="w-full h-[30px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="stage2price" className="block my-2 font-medium text-gray-700">Stage 2 Price</label>
          <input
            type="number"
            name="stage2price"
            id="stage2price"
            required
            value={formData.stage2price}
            readOnly
            placeholder="Stage 2 Price"
            className="w-full h-[30px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="stage3price" className="block my-2 font-medium text-gray-700">Stage 3 Price</label>
          <input
            type="number"
            name="stage3price"
            required
            id="stage3price"
            value={formData.stage3price}
            readOnly
            placeholder="Stage 3 Price"
            className="w-full h-[30px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        </div>
        <div className="mb-4">
          <label htmlFor="numberofitems" className="block text-2xl my-2 font-medium text-gray-700">Number of Items</label>
          <input
            type="number"
            name="numberofitems"
            required
            id="numberofitems"
            value={formData.numberofitems}
            onChange={handleChange}
            placeholder="Number of Items"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="productImage" className="block text-2xl my-2 font-medium text-gray-700">Product Image</label>
          <input
            type="file"
            name="productImage"
            required
            id="productImage"
            onChange={handleChange}
            className="hidden"/>
          <label
            htmlFor="productImage"
            className="cursor-pointer bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-md inline-block">
            Upload Image
          </label>
          <div className='flex justify-center items-center'>
          {previewImage && (
            <img
              src={previewImage}
              alt="Preview"
              className="mt-2"
              style={{ maxWidth: '20%', height: 'auto' }}
            />
          )}
          </div>
        </div>
        <div className='mb-4'>
          <input
            type='checkbox'
            name="terms&conditions"
            onChange={handleChange}
            required
            className='w-4 h-4 mr-2'
          />
          <label htmlFor="terms&conditions" className='text-gray-700'>I agree to the terms and conditions</label>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
          Submit
        </button>
      </form>
    </div>
  );
}

function convertToBase64(file){
  return new Promise((resolve,reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}
