// Partner3.js

import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginSuccess } from '../store/reducer';
import { default as api } from '../store/apiSlice';
import { toast } from 'react-toastify';

export default function Partner3() {
  const [formData, setFormData] = useState({
    emailAddress: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login] = api.useLoginuserMutation();
  // const { data: generateOTPData, error: generateOTPError, refetch: refetchGenerateOTP } = api.useGenerateOTPQuery();
  // const [sendMailMutation] = api.useSendMailMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await login(formData); // Call the login mutation function
      const token = data?.token; // Destructure token from data
      if (error) {
        // Handle login failure
        if (error.status === 404 && error.data.error.includes('cant find user')) {
          toast.error('Email does not exist')
        } else if (error.status === 400 && error.data.error.includes('Password does not match')) {
          toast.error('Password does not match')
        } else if (error.status === 403 && error.data.error.includes('User is not verified')) {
          toast.error('Status: Profile verification pending..!')
        } else {
          setError('Login failed');
        }
        setIsLoading(false); // Set loading state to false
      } else {
        // Handle successful login
        localStorage.setItem('token', token);
        dispatch(loginSuccess(data)); // Dispatch login success action with user data
        navigate('/businessprofile');
      }
    } catch (error) {
      setError('Login failed');
    } finally {
      setIsLoading(false); // Set loading state to false in both success and error cases
    }
  };
  
  const handleForgotPassword = async () => {
    // try {
    //   await refetchGenerateOTP(); // Fetch OTP
    //   if (!generateOTPError) {
    //     const { code } = generateOTPData;
    //     // Send mail with OTP
    //     const { error } = await sendMailMutation({
    //       emailAddress: formData.emailAddress,
    //       FirstName: '', // Add FirstName here if available
    //       code,
    //       subject: 'Password Recovery OTP'
    //     });
    //     if (error) {
    //       console.error('Failed to send mail:', error);
    //       setError('Failed to send OTP');
    //     } else {
    //       navigate('/recover', { state: { emailAddress: formData.emailAddress } }); // Navigate to OTP screen with email address
    //       console.log(code)
    //     }
    //   } else {
    //     console.error('Failed to generate OTP:', generateOTPError);
    //     setError('Failed to generate OTP');
    //   }
    // } catch (error) {
    //   console.error('Failed to generate OTP:', error);
    //   setError('Failed to generate OTP');
    // }
    navigate('/recover', { state: { emailAddress: formData.emailAddress } }); // Navigate to OTP screen with email address
  };


  return (
    <div className="container mx-auto max-w-md mt-8">
      <h2 className="text-2xl font-serif mb-4">Check in</h2>
      <form onSubmit={handleSubmit}>
        {/* Email */}
        <div className="mb-4">
          <label htmlFor="email" className="block font-serif text-gray-700">Email</label>
          <input
            type="email"
            name="emailAddress"
            id="email"
            required
            value={formData.emailAddress}
            onChange={handleChange}
            placeholder="Enter your email"
            className="w-full h-[80px] text-2xl font-serif px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        {/* Password */}
        <div className="mb-4">
          <label htmlFor="password" className="block font-serif text-gray-700">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            required
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            className="w-full h-[80px] text-2xl font-serif px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        {/* Submit Button */}
        <button type="submit" className="bg-[#116a7b] font-serif text-xl w-[40%] h-[60px] text-white p-4" disabled={isLoading}>
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </form>
      <div>
        <h1>Forget password recover : <button onClick={handleForgotPassword}>Reset Password</button></h1>
      </div>
    </div>
  );
}
