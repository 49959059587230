import React from 'react'
import { Link } from 'react-router-dom'
import Section7 from '../sections/section7'
import partner from '/Users/adnan/Desktop/w2/client/src/images/images-3/P-partnerup.svg'
import growth from '/Users/adnan/Desktop/w2/client/src/images/images-3/P-growth.svg'
import rightmarket from '/Users/adnan/Desktop/w2/client/src/images/images-3/P-rightmarket.svg'
import rewarding from '/Users/adnan/Desktop/w2/client/src/images/images-3/P-rewarding.svg'
import brandpresence from '/Users/adnan/Desktop/w2/client/src/images/images-3/P-brandpresence.svg'
import charges from '/Users/adnan/Desktop/w2/client/src/images/images-3/P-charges.svg'


export default function partner1() {
  return (
    <div className='bg-[#628281]'>
      <div className="container  mx-auto flex justify-between items-center p-2">
        <div className='border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2'>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <Link to='/'>
          <div className="drop-main">
          <div className="S animate-ease">S</div>
            <div className="T animate-ease">t</div>
            <div className="A animate-ease">a</div>
            <div className="K animate-ease">k</div>
            <div className="E animate-ease">e</div>
            <div className="P animate-ease">p</div>
            <div className="L animate-ease">l</div>
            <div className="O animate-ease">o</div>
            <div className="T animate-ease">t</div>
          </div>
          </Link>
          </div>
        </div>
        </div>
        <div className="md:flex space-x-4 border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2">
          <h2>business</h2>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 bg-[#628281]'>
        <div className=''>
        <img className='w-[50%] md:w-[80%]' src={partner} alt='partner'></img>
        </div>
        <div className='flex p-2 justify-center items-center'>
          <div>
          <h1 className='text-4xl font-serif text-white'>Unlock New Horizons</h1>
          <h3>Partner with Us to Reach India’s Savvy Millennials and College Students</h3>
        <Link to='/partner-signup' >
          <div className='flex my-2 justify-center items-center h-10 w-[100px] bg-black text-white'>
          <h1 className=''>Signup here</h1>
          </div>
        </Link>
        </div>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 bg-[#889FA5]'>
        <div className=''>
        <img className='w-[50%] md:w-[80%]' src={growth} alt='partner'></img>
        </div>
        <div className='flex p-2 justify-center items-center'>
          <div>
          <h1 className='text-4xl text-white'>Unlocking Growth: Tapping into the Spending Power of India's Millennials and Gen Z</h1>
          <h3>Millennials and Gen Z in India spend up to 3 times more than the national average on shopping, travel, and entertainment. It's their attention that matters.</h3>
          <Link to='/partner-signup' >
          <div className='flex my-2 justify-center items-center h-10 w-[100px] bg-black text-white'>
          <h1 className=''>Signup here</h1>
          </div>
        </Link>
        </div>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 bg-[#B1BDC5]'>
        <div className=''>
        <img className='w-[50%] md:w-[80%]' src={rewarding} alt='partner'></img>
        </div>
        <div className='flex p-2 justify-center items-center'>
          <div>
          <h1 className='text-4xl'>Elevate Your Brand Visibility</h1>
          <h3>Partner with us and watch your brand steal the spotlight, becoming the center of attention in the eyes of young consumers.</h3>
        <Link to='/partner-signup' >
          <div className='flex my-2 justify-center items-center h-10 w-[100px] bg-black text-white'>
          <h1 className=''>Signup here</h1>
          </div>
        </Link>
        </div>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 bg-[#C6C6C6]'>
        <div className=''>
        <img className='w-[50%] md:w-[80%]' src={rightmarket} alt='partner'></img>
        </div>
        <div className='flex p-2 justify-center items-center'>
          <div>
          <h1 className='text-4xl'>Rewards Redefined: Experience Excellence</h1>
          <h3>Discover a rewarding experience like never before, where every interaction brings new opportunities and endless benefits.</h3>
        <Link to='/partner-signup' >
          <div className='flex my-2 justify-center items-center h-10 w-[100px] bg-black text-white'>
          <h1 className=''>Signup here</h1>
          </div>
        </Link>
        </div>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 bg-[#C6C6C6]'>
        <div className=''>
        <img className='w-[50%] md:w-[80%]' src={brandpresence} alt='partner'></img>
        </div>
        <div className='flex p-2 justify-center items-center'>
          <div>
          <h1 className='text-4xl'>Fan Power: Amplify Your Brand's Influence</h1>
          <h3>Join us and unlock the potential of fan power to drive growth and success for your brand.</h3>
        <Link to='/partner-signup' >
          <div className='flex my-2 justify-center items-center h-10 w-[100px] bg-black text-white'>
          <h1 className=''>Signup here</h1>
          </div>
        </Link>
        </div>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 bg-[#ACA9BB]'>
        <div className=''>
        <img className='w-[50%] md:w-[80%]' src={charges} alt='partner'></img>
        </div>
        <div className='flex p-2 justify-center items-center'>
          <div>
          <h1 className='text-4xl font-bold'>0% commission charges</h1>
          <h3 className='text-sm text-gray-800'>Maximize your profits with our zero commission policy. Unlike other platforms, we do not charge any commission fees on your sales, allowing you to keep 100% of your earnings.</h3>
        <Link to='/partner-signup' >
          <div className='flex my-2 justify-center items-center h-10 w-[100px] bg-black text-white'>
          <h1 className=''>Signup here</h1>
          </div>
        </Link>
        </div>
        </div>
      </div>
      <div>
      </div>
      <Section7></Section7>
    </div>
  )
}
