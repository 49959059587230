import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import Section7 from '../section7';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

export default function Autoloan() {
  // Auto Loan state variables
  const [carPrice, setCarPrice] = useState(3000000); // Car price
  const [downPayment, setDownPayment] = useState(20); // Down payment as percentage
  const [loanInterestRate, setLoanInterestRate] = useState(7); // Annual loan interest rate
  const [loanTenure, setLoanTenure] = useState(60); // Loan tenure in months
  const [maintenanceCost, setMaintenanceCost] = useState(3); // Annual maintenance cost as percentage of car price
  const [selectedBrand, setSelectedBrand] = useState('Toyota'); // Selected car brand
  const [monthlyLoanPayment, setMonthlyLoanPayment] = useState(0); // Monthly loan payment
  const [totalLoanCost, setTotalLoanCost] = useState(0); // Total cost of the loan
  const [annualMaintenanceCost, setAnnualMaintenanceCost] = useState(0); // Annual maintenance cost
  const [depreciationValue, setDepreciationValue] = useState(0); // Depreciation value after 4 years

  useEffect(() => {
    // Calculate monthly loan interest rate
    const monthlyInterestRate = loanInterestRate / 12 / 100;
    const downPaymentAmount = (downPayment / 100) * carPrice;
    const loanAmount = carPrice - downPaymentAmount;

    // Calculate monthly loan payment
    const emi = loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTenure) / (Math.pow(1 + monthlyInterestRate, loanTenure) - 1);
    setMonthlyLoanPayment(emi);

    // Calculate total loan cost
    const totalCost = emi * loanTenure;
    setTotalLoanCost(totalCost);

    // Calculate annual maintenance cost based on the selected brand
    let maintenanceCostPercentage = 2; // Default maintenance cost percentage
    if (selectedBrand === 'Toyota') {
      maintenanceCostPercentage = 0.35; // Example maintenance cost percentage for Toyota
    } else if (selectedBrand === 'Honda') {
      maintenanceCostPercentage = 0.65; // Example maintenance cost percentage for Honda
    } else if (selectedBrand === 'Hyundai') {
      maintenanceCostPercentage = 0.46; // Example maintenance cost percentage for Ford
    } else if (selectedBrand === 'Mahindra') {
        maintenanceCostPercentage = 0.3; // Example maintenance cost percentage for Ford
    } else if (selectedBrand === 'Tata') {
        maintenanceCostPercentage = 0.53; // Example maintenance cost percentage for Ford
    } else if (selectedBrand === 'Jeep') {
        maintenanceCostPercentage = 0.75; // Example maintenance cost percentage for Ford
    }
    const annualMaintenance = (maintenanceCostPercentage / 100) * carPrice;
    setAnnualMaintenanceCost(annualMaintenance);

    // Calculate depreciation value after 4 years based on the selected brand
    let depreciation = 0; // Default depreciation value
    if (selectedBrand === 'Toyota') {
      depreciation = carPrice * 0.6; // Example depreciation value for Toyota after 4 years
    } else if (selectedBrand === 'Honda') {
      depreciation = carPrice * 0.55; // Example depreciation value for Honda after 4 years
    } else if (selectedBrand === 'Hyundai') {
      depreciation = carPrice * 0.5; // Example depreciation value for Ford after 4 years
    } else if (selectedBrand === 'Mahindra') {
        depreciation = carPrice * 0.55; // Example depreciation value for Honda after 4 years
    } else if (selectedBrand === 'Tata') {
        depreciation = carPrice * 0.5; // Example depreciation value for Ford after 4 years
    } else if (selectedBrand === 'Jeep') {
        depreciation = carPrice * 0.5; // Example depreciation value for Ford after 4 years
    } 
    setDepreciationValue(depreciation);
  }, [carPrice, downPayment, loanInterestRate, loanTenure, maintenanceCost, selectedBrand]);

  // Data for Doughnut chart
  const data = {
    labels: ['Total Loan Cost', 'Annual Maintenance', 'Depreciation Value'],
    datasets: [
      {
        data: [totalLoanCost, annualMaintenanceCost, depreciationValue],
        backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(75, 192, 192, 0.6)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };

  return (
    <div>
      <div className='bg-[#ECE5C7]'>
        <div className="flex-shrink-0">
          <h1 className="text-3xl bg-white/40 backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]">Stakeplot</h1>
        </div>
        <div className='text-3xl backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]'>
          <h1>Auto Loan Calculator</h1>
        </div>
        <div className="max-w-lg p-6 mx-auto my-10 border-4 border-white rounded-3xl">
          {/* Car price input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="carPrice" className="block text-sm font-medium text-[#116a7b]">
              Car Price
            </label>
            <input
              type="range"
              id="carPrice"
              name="carPrice"
              min="500000"
              max="10000000"
              step="100000"
              value={carPrice}
              onChange={(e) => setCarPrice(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${carPrice}`}</div>
          </div>
          {/* Down payment input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="downPayment" className="block text-sm font-medium text-[#116a7b]">
              Down Payment (%)
            </label>
            <input
              type="range"
              id="downPayment"
              name="downPayment"
              min="0"
              max="100"
              step="1"
              value={downPayment}
              onChange={(e) => setDownPayment(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${downPayment}%`}</div>
          </div>
          {/* Loan interest rate input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="loanInterestRate" className="block text-sm font-medium text-[#116a7b]">
              Loan Interest Rate (%)
            </label>
            <input
              type="range"
              id="loanInterestRate"
              name="loanInterestRate"
              min="1"
              max="20"
              step="0.1"
              value={loanInterestRate}
              onChange={(e) => setLoanInterestRate(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${loanInterestRate}%`}</div>
          </div>
          {/* Loan tenure input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="loanTenure" className="block text-sm font-medium text-[#116a7b]">
              Loan Tenure (Months)
            </label>
            <input
              type="range"
              id="loanTenure"
              name="loanTenure"
              min="12"
              max="120"
              step="1"
              value={loanTenure}
              onChange={(e) => setLoanTenure(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${loanTenure} months`}</div>
          </div>
          {/* Maintenance cost input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="maintenanceCost" className="block text-sm font-medium text-[#116a7b]">
              Annual Maintenance Cost (% of car price)
            </label>
            <input
              type="range"
              id="maintenanceCost"
              name="maintenanceCost"
              min="1"
              max="10"
              step="0.1"
              value={maintenanceCost}
              onChange={(e) => setMaintenanceCost(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${maintenanceCost}%`}</div>
          </div>
          {/* Car brand selection */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
  <label className="block text-sm font-medium text-[#116a7b]">Select Car Brand:</label>
  <div className="flex flex-wrap gap-2">
  <label htmlFor="mahindra" className="flex items-center rounded-lg p-2">
      <input
        type="checkbox"
        id="mahindra"
        name="carBrand"
        value="Mahindra"
        checked={selectedBrand === 'Mahindra'}
        onChange={() => setSelectedBrand('Mahindra')}
        className="peer hidden"
      />
      <label htmlFor="mahindra" className="select-none cursor-pointer rounded-lg border-2 border-gray-200
        py-3 px-6 font-bold text-gray-400 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200">Mahindra</label>
    </label>
    <label htmlFor="tata" className="flex items-center rounded-lg p-2">
      <input
        type="checkbox"
        id="tata"
        name="carBrand"
        value="Tata"
        checked={selectedBrand === 'Tata'}
        onChange={() => setSelectedBrand('Tata')}
        className="peer hidden"
      />
      <label htmlFor="tata" className="select-none cursor-pointer rounded-lg border-2 border-gray-200
        py-3 px-6 font-bold text-gray-400 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200">Tata</label>
    </label>
    <label htmlFor="toyota" className="flex items-center rounded-lg p-2">
      <input
        type="checkbox"
        id="toyota"
        name="carBrand"
        value="Toyota"
        checked={selectedBrand === 'Toyota'}
        onChange={() => setSelectedBrand('Toyota')}
        className="peer hidden"
      />
      <label htmlFor="toyota" className="select-none cursor-pointer rounded-lg border-2 border-gray-200
        py-3 px-6 font-bold text-gray-400 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200">Toyota</label>
    </label>
    <label htmlFor="honda" className="flex items-center rounded-lg p-2">
      <input
        type="checkbox"
        id="honda"
        name="carBrand"
        value="Honda"
        checked={selectedBrand === 'Honda'}
        onChange={() => setSelectedBrand('Honda')}
        className="peer hidden"
      />
      <label htmlFor="honda" className="select-none cursor-pointer rounded-lg border-2 border-gray-200
        py-3 px-6 font-bold text-gray-400 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200">Honda</label>
    </label>
    <label htmlFor="hyundai" className="flex items-center rounded-lg p-2">
      <input
        type="checkbox"
        id="hyundai"
        name="carBrand"
        value="Hyundai"
        checked={selectedBrand === 'Ford'}
        onChange={() => setSelectedBrand('Ford')}
        className="peer hidden"
      />
      <label htmlFor="hyundai" className="select-none cursor-pointer rounded-lg border-2 border-gray-200
        py-3 px-6 font-bold text-gray-400 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200">Hyundai</label>
    </label>
    <label htmlFor="jeep" className="flex items-center rounded-lg p-2">
      <input
        type="checkbox"
        id="jeep"
        name="carBrand"
        value="Jeep"
        checked={selectedBrand === 'Jeep'}
        onChange={() => setSelectedBrand('Jeep')}
        className="peer hidden"
      />
      <label htmlFor="jeep" className="select-none cursor-pointer rounded-lg border-2 border-gray-200
        py-3 px-6 font-bold text-gray-400 transition-colors duration-200 ease-in-out peer-checked:bg-gray-200 peer-checked:text-gray-900 peer-checked:border-gray-200">Jeep</label>
    </label>
  </div>
</div>

          {/* Display Auto Loan details */}
          <div className="mt-8">
            <div className="text-xl text-[#116a7b] font-semibold">Auto Loan Details:</div>
            <div className="text-[#116a7b]">Monthly Loan Payment: ₹{monthlyLoanPayment.toFixed(2)}</div>
            <div className="text-[#116a7b]">Total Loan Cost: ₹{totalLoanCost.toFixed(2)}</div>
            <div className="text-[#116a7b]">Annual Maintenance Cost: ₹{annualMaintenanceCost.toFixed(2)}</div>
            <div className="text-[#116a7b]">Depreciation Value After 4 Years: ₹{depreciationValue.toFixed(2)}</div>
          </div>
          {/* Doughnut chart to visualize auto loan details */}
          <div className="m-2 w-[350px] flex justify-center">
            <Doughnut data={data} options={options} />
          </div>
        </div>
        <div className='mx-4'>
          <h2 className='text-2xl font-bold'>Auto Loan Calculator</h2>
          <p>The Auto Loan Calculator is a tool designed to help you estimate the costs associated with financing a car purchase. By adjusting various parameters, you can understand your monthly loan payments and the overall expenses involved in buying a car on credit.</p>
          <h1 className='text-lg py-2 font-bold'>How to Use the Calculator:</h1>
          <h2 className='font-bold'>Car Price:</h2>
          <p>Use the slider to set the price of the car you intend to purchase.</p>
          <h2 className='font-bold'>Down Payment (%):</h2>
          <p>Adjust the slider to set the percentage of the car price you plan to pay as a down payment.</p>
          <h2 className='font-bold'>Loan Interest Rate (%):</h2>
          <p>Set the annual interest rate for the loan using the slider.</p>
          <h2 className='font-bold'>Loan Tenure (Months):</h2>
          <p>Adjust the slider to set the loan tenure in months (1-120 months).</p>
          <h2 className='font-bold'>Annual Maintenance Cost (% of car price):</h2>
          <p>Set the annual maintenance cost as a percentage of the car price using the slider.</p>
          <h2 className='font-bold'>Select Car Brand:</h2>
          <p>Choose from various car brands to see estimates tailored to specific vehicles.</p>
          <h2 className='text-lg py-2 font-bold'>How It Works:</h2>
          <div className='border border-black rounded-lg p-2'>
          <h2 className='font-bold'>Monthly Loan Payment Calculation:</h2>
          <p>The calculator determines the Equated Monthly Installment (EMI) based on the car price, down payment, loan interest rate, and loan tenure.</p>
          <h2 className='font-bold'>Total Loan Cost Calculation:</h2>
          <p>This includes the total amount paid towards the loan over the specified tenure, considering the EMI payments.</p>
          <h2 className='font-bold'>Annual Maintenance Cost Calculation:</h2>
          <p>The calculator estimates the annual maintenance expenses based on the selected car brand and the specified maintenance cost percentage.</p>
          <h2 className='font-bold'>Depreciation Value Calculation:</h2>
          <p>The estimated depreciation value of the car after four years is calculated based on the selected car brand.</p>
          </div>
          <h2>By comparing these factors, you can make an informed decision about financing your car purchase and understand the financial implications involved.</h2>
        </div>
      </div>
      <Section7></Section7>
    </div>
  );
}

