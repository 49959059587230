import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import Section7 from '../section7';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

export default function EmiCalculator() {
  const [loanAmount, setLoanAmount] = useState(500000); // Initial loan amount
  const [annualInterestRate, setAnnualInterestRate] = useState(10); // Annual interest rate for loan
  const [loanTenure, setLoanTenure] = useState(12); // Loan tenure in months

  const [emi, setEmi] = useState(0); // EMI amount
  const [totalInterestPaid, setTotalInterestPaid] = useState(0); // Total interest paid

  // Calculate EMI details whenever there's a change in inputs
  useEffect(() => {
    // Calculate monthly interest rate
    const monthlyInterestRate = annualInterestRate / 12 / 100;

    // Calculate EMI
    const emi = loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTenure) / (Math.pow(1 + monthlyInterestRate, loanTenure) - 1);

    // Calculate total interest paid
    const totalAmountPaid = emi * loanTenure;
    const totalInterestPaid = totalAmountPaid - loanAmount;

    // Update state with calculated values
    setEmi(Math.round(emi));
    setTotalInterestPaid(Math.round(totalInterestPaid));
  }, [loanAmount, annualInterestRate, loanTenure]);

  // Data for Doughnut chart
  const data = {
    labels: ['Principal', 'Interest'],
    datasets: [
      {
        data: [loanAmount, totalInterestPaid],
        backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(75, 192, 192, 0.6)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };

  return (
    <div>
      <div className='bg-[#ECE5C7]'>
        <div className="flex-shrink-0">
          <h1 className="text-3xl bg-white/40 backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]">Stakeplot</h1>
        </div>
        <div className='text-3xl backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]'>
          <h1>EMI Calculator</h1>
        </div>
        <div className="max-w-md p-6 mx-auto my-10 border-4 border-white rounded-3xl">
          {/* Loan amount input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="loanAmount" className="block text-sm font-medium text-[#116a7b]">
              Loan Amount
            </label>
            <input
              type="range"
              id="loanAmount"
              name="loanAmount"
              min="10000"
              max="10000000"
              step="10000"
              value={loanAmount}
              onChange={(e) => setLoanAmount(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${loanAmount}`}</div>
          </div>
          {/* Annual interest rate input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="annualInterestRate" className="block text-sm font-medium text-[#116a7b]">
              Annual Interest Rate (%)
            </label>
            <input
              type="range"
              id="annualInterestRate"
              name="annualInterestRate"
              min="1"
              max="30"
              step="0.1"
              value={annualInterestRate}
              onChange={(e) => setAnnualInterestRate(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${annualInterestRate}`}</div>
          </div>
          {/* Loan tenure input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="loanTenure" className="block text-sm font-medium text-[#116a7b]">
              Loan Tenure (Months)
            </label>
            <input
              type="range"
              id="loanTenure"
              name="loanTenure"
              min="1"
              max="360"
              step="1"
              value={loanTenure}
              onChange={(e) => setLoanTenure(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${loanTenure} months`}</div>
          </div>
          {/* Display EMI details */}
          <div className="mt-8">
            <div className="text-xl text-[#116a7b] font-semibold">EMI Details:</div>
            <div className="text-[#116a7b]">EMI: ₹{emi}</div>
            <div className="text-[#116a7b]">Total Interest Paid: ₹{totalInterestPaid}</div>
          </div>
          {/* Doughnut chart to visualize principal and interest */}
          <div className="m-2 w-[350px] flex justify-center">
            <Doughnut data={data} options={options} />
          </div>
        </div>
        <div className='mx-4'>
          <h2 className='text-xl font-bold '>EMI calculator</h2>
          <h2>EMI stands for Equated Monthly Installment. It is a fixed payment amount made by a borrower to a lender at a specified date each calendar month. The primary components of an EMI are the principal amount (the amount borrowed) and the interest on the loan.</h2>
          <h2 className='text-lg py-2 font-bold'>How to Use:</h2>
          <h2>Loan Amount:</h2>
          <p>Input: Adjust the slider to set your loan amount (e.g., ₹500,000).</p>
          <h2>Annual Interest Rate:</h2>
          <p>Input: Adjust the slider to set your annual interest rate (e.g., 10%).</p>
          <h2>Loan Tenure:</h2>
          <p>Input: Adjust the slider to set your loan tenure in months (e.g., 12 months).</p>
          <h2 className='text-lg py-2 font-bold'>How It Works:</h2>
          <div className='border border-black rounded-lg p-2'>
          <h1>Calculations:</h1>
          <p>Monthly payment calculated using the loan amount, interest rate, and tenure.</p>
          <p>The total interest paid over the loan tenure, calculated as the difference between the total amount paid and the principal.</p>
          </div>
          <h2 className='text-lg py-2 font-bold'>Importance of EMI:</h2>
          <p><span className='text-2xl underline'>Financial Planning: </span> Helps borrowers budget their monthly expenses by knowing exactly how much they need to set aside for loan repayment.</p>
          <p><span className='text-2xl underline'>Credit Score Impact: </span> Regular and timely payment of EMIs positively impacts the borrower’s credit score.</p>
        </div>
      </div>
      <Section7></Section7>
    </div>
  );
}
