import React, { useEffect, useState } from 'react';
import { default as api } from '../store/apiSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct import

export default function Partner4() {
  const [emailAddress, setEmailAddress] = useState(null);
  const navigate = useNavigate();

  const userLogout = () => {
    localStorage.removeItem('token');
    navigate('/partner-up');
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken); // Decode the token
      setEmailAddress(decodedToken.emailAddress);
    }
  }, []);

  const { data: partnerDetails, isLoading, isError, refetch } = api.useGetPDetailsQuery(emailAddress);

  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    phoneNumber: '',
    emailAddress: '',
    category: '',
    years: '',
    brandName: '',
    instagramLink: '',
    websiteLink: '',
    competitors:'',
    maxfulfilledorders:'',
  });

  const [updateDetails] = api.useUpdateDetailsMutation();
  useEffect(() => {
    if (partnerDetails) {
      setFormData({
        FirstName: partnerDetails.FirstName || '',
        LastName: partnerDetails.LastName || "",
        phoneNumber: partnerDetails.phoneNumber || '',
        emailAddress: partnerDetails.emailAddress || '',
        category: partnerDetails.category,
        years: partnerDetails.years,
        brandName: partnerDetails.brandName,
        instagramLink: partnerDetails.instagramLink,
        websiteLink: partnerDetails.websiteLink,
        competitors: partnerDetails.competitors,
        maxfulfilledorders: partnerDetails.maxfulfilledorders,
      });
    }
  }, [partnerDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDetails({ partnerId: partnerDetails._id, details: formData }, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }})
      toast.success('Details updated successfully..!')
      refetch()
    } catch (error) {
      toast.error('Error:');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <div className='m-4'>
            <div className="container  mx-auto flex justify-between items-center py-2">
        <div className='border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white '>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <div className="drop-main">
          <div className="S animate-ease">S</div>
            <div className="T animate-ease">t</div>
            <div className="A animate-ease">a</div>
            <div className="K animate-ease">k</div>
            <div className="E animate-ease">e</div>
            <div className="P animate-ease">p</div>
            <div className="L animate-ease">l</div>
            <div className="O animate-ease">o</div>
            <div className="T animate-ease">t</div>
          </div>
          </div>
        </div>
        </div>
        <div className='p-2 text-white border bg-[#116a7b] text-xl'>
        <button onClick={userLogout}>Logout</button>
      </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
        <label htmlFor="FirstName" className="block text-2xl my-2 font-medium text-gray-700">First Name</label>
          <input
            type="text"
            id='FirstName'
            name="FirstName"
            value={formData.FirstName}
            onChange={handleChange}
            placeholder="First Name"
            className="w-full h-[60px] text-xl px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
        <label htmlFor="LastName" className="block text-2xl my-2 font-medium text-gray-700">Last Name</label>
          <input
            type="text"
            name="LastName"
            id="LastName"
            value={formData.LastName}
            onChange={handleChange}
            placeholder="Last Name"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
        <label htmlFor="phoneNumber" className="block text-2xl my-2 font-medium text-gray-700">Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
        <label htmlFor="emailAddress" className="block text-2xl my-2 font-medium text-gray-700">Email Address</label>
          <input
            type="email"
            name="emailAddress"
            id="emailAddress"
            value={formData.emailAddress}
            onChange={handleChange}
            placeholder="Email Address"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
        <label htmlFor="category" className="sr-only">Underline select</label>
          <select
            name="category"
            id='category'
            required
            value={formData.category}
            onChange={handleChange}
            className="block py-2.5 px-2 w-full h-[60px] text-xl text-black bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-black dark:border-black focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="">Select Category</option>
            <option value="fashion&apparel">Fashion & Apparel</option>
            <option value="travel&hospitality">Travel & Hospitality</option>
            <option value="technology&electronics">Technology & Electronics</option>
            <option value="Entertainment&Media">Entertainment & Media</option>
            <option value="Beauty&Cosmetics">Beauty & Cosmetics</option>
            <option value="Home&Lifestyle">Home & Lifestyle</option>
            <option value="food">Food</option>
            <option value="Education&Learning">Education & Learning</option>
            <option value="Sports&recreation">Sports & Recreation</option>
            <option value="PetCare">Pet Care</option>
          </select>
        </div>
        <div className="mb-4">
          <select
            name="years"
            required
            value={formData.years}
            onChange={handleChange}
            className="block py-2.5 px-2 w-full h-[60px] text-xl text-black bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-black dark:border-black focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value="">Operating years</option>
            <option value="less than 1 year">less than 1 year</option>
            <option value="2-3 years">2-3 years</option>
            <option value="4-5 years">4-5 years</option>
            <option value="more than 5 years">more than 5 years</option>
          </select>
        </div>
        <div className="mb-4">
        <label htmlFor="brandName" className="block text-2xl my-2 font-medium text-gray-700">Brand :</label>
          <input
            type="text"
            name="brandName"
            id="brandName"
            value={formData.brandName}
            onChange={handleChange}
            placeholder="Brand Name"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
        <label htmlFor="instagramLink" className="block text-2xl my-2 font-medium text-gray-700">Instagram handle:</label>
          <input
            type="text"
            name="instagramLink"
            id="instagramLink"
            value={formData.instagramLink}
            onChange={handleChange}
            placeholder="Instagram Link"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
        <label htmlFor="websiteLink" className="block text-2xl my-2 font-medium text-gray-700">Web url:</label>
          <input
            type="text"
            name="websiteLink"
            id="websiteLink"
            value={formData.websiteLink}
            onChange={handleChange}
            placeholder="Website Link"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
        <label htmlFor="competitors" className="block text-2xl my-2 font-medium text-gray-700">Competitors in space:</label>
          <input
            type="text"
            name="competitors"
            id="competitors"
            value={formData.competitors}
            onChange={handleChange}
            placeholder="Competitors in your space"
            className="w-full h-[60px] px-3 py-2 border border-gray-300 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
        <label htmlFor="maxfulfilledorders" className="block text-2xl my-2 font-medium text-gray-700">Maximum orders fulfilled in a day:</label>
          <input
            type="Number"
            name="maxfulfilledorders"
            id="maxfulfilledorders"
            value={formData.maxfulfilledorders}
            onChange={handleChange}
            placeholder="Maximum fulfilled orders in a day"
            className="w-full px-3 h-[60px] py-2 border border-gray-300  focus:outline-none focus:border-indigo-500"
          />
        </div>
        <button type="submit" className="bg-[#116a7b] text-3xl text-white px-4 py-2 ">
          Update
        </button>
      </form>
    </div>
  );
}


