import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Logo1 from '/Users/adnan/Desktop/w2/client/src/images/logo.png'
import blogs from './blogs'; // Import the blogs array
import Section6 from '../section6';

export default function Bloghome() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
}, []);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };
      const First10Words = (content) => {
        const words = content.split(' ');
        const first10Words = words.slice(0, 10).join(' ');
        return first10Words;
      };
  return (
    <div className='bg-[#ece5c7]'>
          <nav className="p-2 fixed top-0 left-0 w-full z-10">
      <div className="container flex justify-between items-center">
      <div className='flex border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2'>
          <div className='flex justify-center items-center'>
          <img className='w-8 h-8 rounded-full mx-1' src={Logo1} alt=''/>
          </div>
        <Link to='/'>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <div className="drop-main">
          <div className="S animate-ease">S</div>
            <div className="T animate-ease">t</div>
            <div className="A animate-ease">a</div>
            <div className="K animate-ease">k</div>
            <div className="E animate-ease">e</div>
            <div className="P animate-ease">p</div>
            <div className="L animate-ease">l</div>
            <div className="O animate-ease">o</div>
            <div className="T animate-ease">t</div>
            
          </div>
          </div>
        </div>
        </Link>
        </div>
        <div className="hidden md:flex border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2">
          <Link to='/' className="text-[#116a7b] underline-animation m-2">Home</Link>
          <Link to='/blogs' className="text-[#116a7b] underline-animation m-2">Blogs</Link>
          <Link to='/calculators' className="text-[#116a7b] underline-animation m-2">Calculators</Link>
        </div>
        <div className="md:hidden flex items-center pr-6">
          <button onClick={toggleMenu} className="text-[#116a7b] focus:outline-none">
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
            </svg>
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className=" w-[100%] z-10 md:hidden bg-opacity-20 bg-white backdrop-blur-md rounded-2xl border-white p-2">
        <Link to='/' className="text-[#116a7b] underline-animation ">Home</Link>
        <Link to='/blogs' className="block py-2 text-gray-800 transition duration-300">
          <div className='flex'>
          <h2 className='underline-animation text-[#116a7b] flex-wrap'>Blogs</h2>
          </div>
          </Link>
        <div className="relative" >
        <Link to='/calculators' className="block py-2 text-gray-800 transition duration-300">
          <div className='flex'>
          <h2 className='underline-animation text-[#116a7b]  flex-wrap'>Calculators</h2>
          </div>
          </Link>
        </div>
      </div>
      )}
    </nav>
    <div className='pt-[95px] mx-8'>
        <h1 className='text-center text-4xl'>Blog</h1>
        <div className='flex justify-center my-4'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-4'>
      {blogs.map((blog, index) => (
        <div key={index} className='px-4'>
          <Link to={`/blogs/${encodeURIComponent(blog.title.replace(/\s+/g, '-'))}`}>
            <div className='flex justify-center px-4 pt-6'>
            <img className='rounded-md w-[250px]' src={blog.image} alt={blog.title} />
            </div>
            </Link>
            <Link to={`/blogs/${encodeURIComponent(blog.title.replace(/\s+/g, '-'))}`}>
            <div className='border border-[#116a7b] rounded-lg'>
            <h2 className='px-2 text-2xl font-serif'>{blog.title}</h2>
            <p className='px-2'>{First10Words(blog.content)}...</p>
            <div className='w-[50%] pt-4 px-2'>
            <div className='flex justify-center text-lg mb-2 p-2 border border-gray-400 hover:bg-black hover:text-white'>
            Learn more
            </div>
            </div>
            </div>
            </Link>
            </div>
      ))}
      </div>
      </div>
    </div>
    <Section6></Section6>
    </div>
  )
}
