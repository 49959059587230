import React, { useState,useEffect, useCallback } from 'react';
import Section7 from './section7';
import day1i from '/Users/adnan/Desktop/w2/client/src/images/game-images/day-1.svg'
import day7i from '/Users/adnan/Desktop/w2/client/src/images/game-images/day-7.svg'
import day15i from '/Users/adnan/Desktop/w2/client/src/images/game-images/day-15.svg'
import day22i from '/Users/adnan/Desktop/w2/client/src/images/game-images/day-22.svg'
import day30i from '/Users/adnan/Desktop/w2/client/src/images/game-images/day-30.svg'
import restart from '/Users/adnan/Desktop/w2/client/src/images/game-images/restart.svg'
import Randominfo from '/Users/adnan/Desktop/w2/client/src/images/Randominfo.svg'

const days = [1, 7, 15, 22, 30];
const initialBalances = [6000, 4500, 8000];
const initialRating = 0;

const actions = {
  highExpense: [
    { name: 'Dine out', amount: -260, rating: 6 },
    { name : 'Rent', amount: -2000, rating: 10},
    { name: 'Shopping', amount: -550, rating: 7 },
    { name: 'Saving', amount: 1500, rating: 10 },
    { name: 'Cosmetics', amount: -2000, rating: 7 },
    { name: 'Pub', amount: -2500, rating: 4 },
  ],
  averageExpense: [
    { name: 'Groceries', amount: -450, rating: 10 },
    { name: 'Movies', amount: -300, rating: 8 },
    { name: 'Trip', amount: -3000, rating:6},
    { name: 'Party', amount: -2500, rating:5},
    { name: 'Smoke', amount: -300, rating: 1 },
    { name: 'Petrol', amount: -500, rating: 7 },
    { name: 'Gym Membership', amount: -700, rating: 10 },
  ],
  lowExpense: [
    { name: 'Coffee', amount: -100, rating: 7 },
    { name: 'Snacks & Drinks', amount: -150, rating: 6 },
    { name: 'Alcohol', amount: -1050, rating: 2 },
    { name: 'Internet', amount: -650, rating: 9 },
    { name: 'Skincare', amount: -1500, rating: 8 },
    { name: 'Books', amount: -200, rating: 10 },
  ],
  responsibleActions: [
    { name: 'Clear EMIs', amount: -1300, rating: 8 },
    { name: 'Pay Bills', amount: -1200, rating: 10 },
    { name: 'Invest Monthly', amount: 500, rating: 10 },
  ],
};

const randomEvents = [
  { name: 'Won a Lottery', amount: 1000, probability: 0.1 },
  { name: 'Unexpected Medical Bill', amount: -850, probability: 0.4 },
  { name: 'Found Money on the Street', amount: 100, probability: 0.3 },
  { name: 'Car Repair', amount: -1000, probability: 0.2 },
  { name: 'Evening snacks', amount: -150, probability: 0.7 },
  { name: 'Travelling expense', amount: -300, probability: 0.5 },
  { name: 'Unexpected party', amount: -500, probability: 0.4 },
];

const getActionsForDay = (day) => {
  if (day === 1) {
    return actions.highExpense;
  } else if (day === 7) {
    return actions.averageExpense;
  } else if (day === 15) {
    return actions.lowExpense;
  } else if (day === 22) {
    return actions.averageExpense;
  } else {
    return actions.responsibleActions;
  }
};

const getRandomInitialBalance = () => {
  const randomIndex = Math.floor(Math.random() * initialBalances.length);
  return initialBalances[randomIndex];
};

const getRandomActions = (actionsArray) => {
    const shuffled = actionsArray.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 4);
  };

const ImageForDay = (day) => {
    if (day === 1) {
        return day1i
    } else if (day === 7){
        return day7i
    } else if (day === 15){
        return day15i
    } else if (day === 22){
        return day22i
    } else if (day === 30){
        return day30i
    } else {
        return restart
    }
}

const GameSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
}, []);
  const [balance, setBalance] = useState(getRandomInitialBalance());
  const [rating, setRating] = useState(initialRating);
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [spendingHistory, setSpendingHistory] = useState([]);
  const [randomEvent, setRandomEvent] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [message, setMessage] = useState('');


  const triggerRandomEvent = useCallback(() => {
    const event = randomEvents.find((e) => Math.random() < e.probability);
    if (event) {
      setBalance((prevBalance) => prevBalance + event.amount);
      setSpendingHistory((prevHistory) => [...prevHistory, event]);
      setRandomEvent(event);
    }
  }, []);

  const endGame = useCallback(() => {
    setGameOver(true);
    const spendingBehavior = analyzeSpendingBehavior();
    setMessage(`Your spending behavior is: ${spendingBehavior}`);
  }, [spendingHistory]);

  const handleAction = (action) => {
    setBalance((prevBalance) => prevBalance + action.amount);
    setSpendingHistory((prevHistory) => [...prevHistory, action]);
    setRating((prevRating) => prevRating + action.rating);

    setCurrentDayIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex === days.length) {
        triggerRandomEvent();
        endGame();
      }
      return newIndex;
    });
  };

  const analyzeSpendingBehavior = () => {
    const totalSpent = spendingHistory.reduce(
      (sum, action) => sum + (action.amount < 0 ? -action.amount : 0),
      0
    );
    if (totalSpent > 5000) {
      return 'Big Spender';
    } else if (totalSpent > 4000) {
      return 'uncontrolled Budget';
    } else if (totalSpent > 3000) {
      return 'Controlleddd Budget';
    } else if (totalSpent > 2500) {
      return 'Finance Guru';
    } else if (totalSpent > 1500) {
      return 'Thrifty Saver';
    } else {
      return 'Frugal';
    }
  };
  const averageRating = Math.round(rating / days.length);


  const getNextMonthAdvice = () => {
    if (balance > 7000) {
      return `You're living lavishly this month!`;
    } else if (balance > 5000) {
      return 'Next month needs to be calculative';
    } else if (balance > 3000) {
        return 'Focus on saving and cutting down unnecessary expenses.';
    } else if (balance > 1000) {
        return `It's time to tighten your belt and be frugal.`;
    } else {
      return 'Warning : You are messed up.Major changes are needed';
    }
  };

  const ratingTips = () => {
    if (averageRating > 8) {
      return [
        'You spend money wisely.',
        'Keep up the great work managing your finances with stakeplot more easily!',
        'Consider investing in long-term savings options.'
      ];
    } else if (averageRating > 7) {
      return [
        'You need to be more precise.',
        'There’s room for improvement in your budgeting skills.',
        'Track your expenses in stakeplot to identify areas for savings.'
      ];
    } else if (averageRating > 5) {
      return [
        'You need to be controlled.',
        'Consider setting stricter budgets for discretionary spending.',
        'Avoid impulse purchases to stay within budget.'
      ];
    } else if (averageRating > 3) {
      return [
        'You need to be more disciplined with your spending.',
        'Prioritize your needs over wants.',
        'Improve your spending behaviour with stakeplot.'
      ];
    } else {
      return [
        'Your financial habits are concerning.',
        'It’s time to make significant changes to avoid debt.',
        'Create a strict budget and stick to it.',
        'Look for additional income sources to stabilize your finances.'
      ];
    }
  };
  
  const tips = ratingTips();

  const resetGame = () => {
    setBalance(getRandomInitialBalance());
    setRating(initialRating);
    setCurrentDayIndex(0);
    setSpendingHistory([]);
    setRandomEvent(null);
    setGameOver(false);
    setMessage(''); // Reset the description visibility
  };

  const currentDay = days[currentDayIndex];
  const actionsForDay = getActionsForDay(currentDay);
  const randomActionsForDay = getRandomActions(actionsForDay);
  const [info, Openinfo] = useState(false);
  const toggleinfo = () => {
    Openinfo(!info);
  };

  const [ratinginfo , Seeratinginfo] = useState(false)
  const togglerating = () => {
    Seeratinginfo(!ratinginfo)
  }

  return (
    <div>
    <div className="bg-[#ece5c7] p-4 rounded-lg shadow-md text-center">
    <div className="container  flex justify-start items-center">
        <div className='border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2'>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <div className="drop-main">
          <div class="S animate-ease">S</div>
            <div class="T animate-ease">t</div>
            <div class="A animate-ease">a</div>
            <div class="K animate-ease">k</div>
            <div class="E animate-ease">e</div>
            <div class="P animate-ease">p</div>
            <div class="L animate-ease">l</div>
            <div class="O animate-ease">o</div>
            <div class="T animate-ease">t</div>
          </div>
          </div>
        </div>
        </div>
    </div>
      <h1 className="text-2xl text-start mt-6 font-bold mb-4 font-serif">CashCraft</h1>
      <div className='m-2 text-start border border-black p-2'>
        <h1 className='underline text-lg text-[#116a7b]'>Lets check out your budgeting habits</h1>
        <h2>Pick a spending category and see where your choices lead you on a random day of the month!</h2>
      </div>
      <div className="mb-4 border border-white bg-white opacity-80 rounded-2xl shadow-md p-2">
        <p>Current Balance: <span className='text-xl'>₹{balance}</span></p>
      </div>
      <div className="mb-4">
        <p>Day: {currentDay}</p>
      </div>
      <div className='flex justify-center'>
      <div className='m-2 w-[150px] h-[150px]'>
        <img src={ImageForDay(currentDay)} alt="image day" />
      </div>
      </div>
      {!gameOver && (
        <h1 className='text-2xl font-sans'>Pick one:</h1>
      )}
      
      <div className="jello-animation  mb-4 grid grid-cols-2" >
        {!gameOver &&
          randomActionsForDay.map((action) => (
            <div className='flex justify-center items-center'>
            <button
              key={action.name}
              onClick={() => handleAction(action)}
              className="m-2 w-40 h-40 lg:w-3/5  p-2 bg-gradient-to-b from-custom-blue-900 via-custom-blue-700 to-custom-blue-500 text-white rounded-full shadow-lg lg:text-2xl hover:scale-105 transform transition duration-300"
              >
              {action.name} ({action.amount > 0 ? '+' : ''}₹{action.amount})
            </button>
            </div>
          ))}
          
      </div>
      {randomEvent && (
        <div className="mb-4">
          <div className='flex justify-center'>
            <h1 className=''>Random Event: {randomEvent.name} ({randomEvent.amount > 0 ? '+' : ''}₹{randomEvent.amount})</h1>
            <img onClick={toggleinfo} className='w-6 h-6 ml-2' src={Randominfo} alt='info' />
            </div>
            {info && (
              <div>In our world, any random event can occur unpredictably, shaping the way we spend our money.</div>
            )}
        </div>
      )}
      {gameOver && (
        <div>
          <div className="font-bold mb-4">{message}</div>
          <div className="mb-4">
            Total Spendings: ₹{spendingHistory.reduce((sum, action) => sum + (action.amount < 0 ? -action.amount : 0), 0)}
          </div>
          <div className="mb-4">
            <div className='flex justify-center'>
            <h2 className="text-xl font-bold mb-2">Average Rating: <span className="text-lg mr-2">{averageRating}</span></h2>
            <img onClick={togglerating} className='w-6 h-6 m-1' src={Randominfo} alt='info'/>
            </div>
            <div className="flex items-center">
              <div className="w-full bg-white rounded-full h-6">
                <div
                  className={`h-6 rounded-full ${
                    averageRating > 8 ? 'bg-green-500' : averageRating > 5 ? 'bg-yellow-500' : 'bg-red-500'
                  }`}
                  style={{ width: `${(averageRating / 10) * 100}%` }}
                ></div>
              </div>
            </div>
            {ratinginfo && (
              <div>
                The rating tell you how best your spending behaviour was.
              </div>
            )}
          </div>
          <div className="mb-4 ">
            <h2 className="text-xl font-bold mb-2">Badges</h2>
            <ul className="list-none p-0 flex flex-wrap gap-2">
              {spendingHistory.find((action) => action.name === 'Clear EMIs') && (
                <li className="bg-[#FAF9F6] shadow-md p-2 animate-wiggle text-lg rounded-full flex items-center gap-2">
                  <span role="img" aria-label="Responsible Saver">🏦</span> Responsible
                </li>
              )}
              {spendingHistory.find((action) => action.name === 'Gym Membership') && (
                <li className="bg-[#FAF9F6] p-2 shadow-md animate-wiggle text-lg rounded-full flex items-center gap-2">
                  <span role="img" aria-label="Gym freak..!">🏋🏽💪🏻</span> Gym freak..!
                </li>
              )}
              {spendingHistory.find((action) => action.name === 'Party') && spendingHistory.find((action) => action.name === 'Pub') && (
                <li className="bg-[#FAF9F6] p-2 shadow-md animate-wiggle text-lg rounded-full flex items-center gap-2">
                  <span role="img" aria-label="Social Butterfly">🦋 🍾</span> Social Butterfly
                </li>
              )}
              {spendingHistory.find((action) => action.name === 'Cosmetics') && spendingHistory.find((action) => action.name === 'Skincare') && (
                <li className="bg-[#FAF9F6] p-2 shadow-md animate-wiggle text-lg rounded-full flex items-center gap-2">
                  <span role="img" aria-label="Beauty Boss">💄</span> Beauty Boss
                </li>
              )}
              {spendingHistory.find((action) => action.name === 'Rent') && spendingHistory.find((action) => action.name === 'Groceries') && (
                <li className="bg-[#FAF9F6] p-2 shadow-md animate-wiggle text-lg rounded-full flex items-center gap-2">
                  <span role="img" aria-label="Family Man">🏠</span> Family Man
                </li>
              )}
              {spendingHistory.find((action) => action.name === 'Trip') && spendingHistory.find((action) => action.name === 'Petrol') && (
                <li className="bg-[#FAF9F6] p-2 shadow-md animate-wiggle text-lg rounded-full flex items-center gap-2">
                  <span role="img" aria-label="Wanderer">🏔️🏍️</span> Wanderer
                </li>
              )}
              {spendingHistory.find((action) => action.name === 'Party') && spendingHistory.find((action) => action.name === 'Alcohol') && (
                <li className="bg-[#FAF9F6] p-2 shadow-md animate-wiggle text-lg rounded-full flex items-center gap-2">
                  <span role="img" aria-label="Party Animal">🎉</span> Party Animal
                </li>
              )}
              {spendingHistory.find((action) => action.name === 'Smoke') && (
                <li className="bg-[#FAF9F6] p-2 shadow-md animate-wiggle text-lg rounded-full flex items-center gap-2">
                  <span role="img" aria-label="Breath Easy">🚭</span> Breath Easy
                </li>
              )}
              {spendingHistory.find((action) => action.name === 'Snacks & Drinks') && (
                <li className="bg-[#FAF9F6] p-2 shadow-md animate-wiggle text-lg rounded-full flex items-center gap-2">
                  <span role="img" aria-label="Snacks & Drinks">🥪🌮</span> Masala Maestro
                </li>
              )}
            </ul>
          </div>
          <div>
          <div className='border m-2 p-2 rounded-md opacity-70 bg-white'>
          <h2 className="text-xl font-bold mb-2">Tips</h2>
          <ul>
            {tips.map((tip, index) => (
              <li key={index}>{tip}</li>
            ))}
          </ul>
        </div>
          </div>
          <div className="mb-4 m-2 border border-black p-2">
            <p>{getNextMonthAdvice()}</p>
          </div>
          <div className='bg-white m-2 border rounded-md'>
          <h1>Improve the budgeting skill, try once more..!</h1>
          <button onClick={resetGame} className="m-2 p-2 bg-[#116a7b] text-white ">Play Again</button>
          </div>
        </div>
      )}
    </div>
    <Section7></Section7>
    </div>
  );
};

export default GameSection;
