import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {default as api} from '../store/apiSlice'
import 'react-toastify/dist/ReactToastify.css';
import Logo1 from '/Users/adnan/Desktop/w2/client/src/images/logo1.svg'


export default function DeletionRequestForm() {
  const [message, setMessage] = useState({
    email: '',
    reason: '',
    confirmed: false,
  });
  const [submitDeletionRequest] = api.useAccountdeletionMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessage({
      ...message,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setMessage({
      ...message,
      confirmed: e.target.checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitDeletionRequest(message).unwrap();
      toast.success('Account deletion request submitted successfully!');
      setMessage({
        email: '',
        reason: '',
        confirmed: false,
      });
    } catch (error) {
      toast.error('Failed to submit account deletion request. Please try again.');
      console.log(error)
    }
  };

  return (
    <div>
        <div className='flex border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2'>
          <div className='flex justify-center items-center'>
          <img className='w-8 h-8 rounded-full mx-1' src={Logo1} alt=''/>
          </div>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <div className="drop-main">
          <div className="S animate-ease">S</div>
            <div className="T animate-ease">t</div>
            <div className="A animate-ease">a</div>
            <div className="K animate-ease">k</div>
            <div className="E animate-ease">e</div>
            <div className="P animate-ease">p</div>
            <div className="L animate-ease">l</div>
            <div className="O animate-ease">o</div>
            <div className="T animate-ease">t</div>
          </div>
          </div>
        </div>
        </div>
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-2 text-center">Request Account Deletion</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label className="block text-gray-700">Email Address</label>
            <input
              type="email"
              name="email"
              value={message.email}
              onChange={handleChange}
              placeholder="Email"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#116a7b]"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Reason</label>
            <textarea
              name="reason"
              value={message.reason}
              onChange={handleChange}
              placeholder="Reason for deletion"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#116a7b]"
              required
            ></textarea>
          </div>
          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              name="confirmed"
              checked={message.confirmed}
              onChange={handleCheckboxChange}
              className="mr-2"
              required
            />
            <label className="text-gray-700">
              I understand that deleting my account will remove all my data from Stakeplot.
            </label>
          </div>
          <button
            type="submit"
            className="w-full bg-[#116a7b] text-white py-3 rounded-lg hover:bg-[#0d5a63] transition duration-200"
            disabled={!message.confirmed}
          >
            Confirm
          </button>
        </form>
      </div>
    </div>
    </div>
  );
}
