import './App.css';
import React from 'react';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import Section1 from './sections/section1';
import Bloghome from './sections/blogs/bloghome';
import Blogpage from './sections/blogs/blogpage';
import Partner1 from './partners/partner1';
import Partner2 from './partners/partner2';
import Partner3 from './partners/partner3';
import Gamesection from './sections/gamesection';
import PartnerRecovery from './partners/partnerRecovery';
import Resetpassword from './partners/resetpassword';
import Partner4 from './partners/partner4';
import Partnerhome from './partners/partnerhome';
import PartnerO from './partners/partnerO';
import Calculatorhome from './sections/calculators/calculatorhome';
import Emicalculator from './sections/calculators/emicalculator';
import Creditcardpayoff from './sections/calculators/creditcardpayoff';
import Autoloan from './sections/calculators/autoloan';
import RentvsBuy from './sections/calculators/RentvsBuy';
import Savingsgoal from './sections/calculators/savingsgoal';
import Breakcost from './sections/calculators/Breakcost';
import Policy from './sections/policy';
import AppAccountdeletion from './sections/appAccountdeletion';

//middleware to protect auth
import Auth from './middleware/auth';
const router = createBrowserRouter([
  {
    path:'/',
    element: <Section1></Section1>
  },
  {
    path:'/partner-up',
    element: <Partner1></Partner1>
  },
  {
    path:'/partner-signup',
    element: <Partner2></Partner2>
  },
  {
    path:'/partner-login',
    element: <Partner3></Partner3>
  },
  {
    path:'/businessprofile',
    element: <Auth><Partnerhome></Partnerhome></Auth>
  },
  {
    path:'/profileinfo',
    element: <Auth><Partner4></Partner4></Auth>
  },
  {
    path:'/createoffer',
    element: <Auth><PartnerO></PartnerO></Auth>
  },
  {
    path:'/recover',
    element:<PartnerRecovery></PartnerRecovery>
  },
  {
    path:'/resetpassword',
    element: <Resetpassword></Resetpassword>
  },
  {
    path:'/blogs',
    element: <Bloghome></Bloghome>
  },
  {
    path:'/blogs/:title',
    element: <Blogpage></Blogpage>
  },
  {
    path : '/budgeting',
    element : <Gamesection></Gamesection>
  },
  {
    path:'/calculators',
    element:<Calculatorhome></Calculatorhome>
  },
  {
    path:'/emi-calculator',
    element : <Emicalculator></Emicalculator>
  },
  {
    path:'/credit-card-payoff-calculator',
    element : <Creditcardpayoff></Creditcardpayoff>
  },
  {
    path:'/auto-loan-calculator',
    element : <Autoloan></Autoloan>
  },
  {
    path :'/rent-buy-calculator',
    element : <RentvsBuy></RentvsBuy>
  },
  {
    path : '/savings-goal-calculator',
    element : <Savingsgoal></Savingsgoal>
  },
  {
    path : '/trip-vacation-cost-calculator',
    element : <Breakcost></Breakcost>
  },
  {
    path :'/account-deletion',
    element : <AppAccountdeletion></AppAccountdeletion>
  },
  {
    path : '/privacy-policy',
    element : <Policy></Policy>
  }
])

function App() {
  return (
    <main>
      <RouterProvider router={router}></RouterProvider>
      <ToastContainer />
    </main>
  );
}

export default App;
