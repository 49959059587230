import React, {useState} from 'react'
import { toast } from 'react-toastify';
import {default as api} from '../store/apiSlice'

export default function Enroll() {
    const [message, setMessage] = useState({
        email :''
    });
    const [enroll] = api.useEnrollsMutation()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMessage({
          ...message,
          [name]: value
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await enroll(message).unwrap();
          toast.success('Thanks for Joining...!');
          setMessage({
            email :''
          });
        } catch (error) {
          toast.error('Failed to Join. Please try again.');
        }
  };

  return (
    <div className="flex items-center justify-center ">
      <div className=" max-w-md w-[80%] ">
        <h2 className="text-2xl font-bold mb-2 text-center">Until then..</h2>
        <h2 className="text-xl mb-4 text-center">Unlock your financial potential— <span className='text-xl font-bold'>Enroll now for early access to Stakeplot.</span> Stay ahead with exclusive updates, and connect with friends to share tips and insights for a better financial future!</h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
        <div className='flex w-full'>
          <input
            name="email"
            value={message.email}
            required
            onChange={handleChange}
            placeholder="Email"
            className=" h-[50px] w-[65%] font-serif text-black text-2xl px-3 py-2 border border-gray-300 rounded-l-full focus:outline-none focus:border-[#116a7b]"
          />
          <div className="">
            <button
              type="submit"
              className="bg-[#116a7b] h-[50px] mb-4 text-white font-bold py-3 px-2  hover:bg-[#0d5a63] rounded-r-full focus:outline-none focus:ring-2 focus:ring-[#116a7b]"
            >
              Join Clan.!
            </button>
          </div>
          </div>
        </form>
        
      </div>
    </div>
  )
}
