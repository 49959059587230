import React, {useState} from 'react'
import { toast } from 'react-toastify';
import {default as api} from '../store/apiSlice'

export default function Reviews() {
    const [message, setMessage] = useState({
        review :''
    });
    const [reviews] = api.useReviewsMutation()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMessage({
          ...message,
          [name]: value
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await reviews(message).unwrap();
          toast.success('Thanks for the feedback...!');
          setMessage({
            review: ''
          });
        } catch (error) {
          toast.error('Failed to store message. Please try again.');
        }
  };

  return (
    <div className="flex items-center justify-center ">
      <div className="bg-[#ece5c7] shadow-md rounded-lg p-4 w-full max-w-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">Feedback</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            name="review"
            value={message.review}
            required
            onChange={handleChange}
            placeholder="Got any opinion, write to us it's anonymous...!"
            className="w-full h-32 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-none"
          />
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-[#116a7b] text-white font-bold py-2 px-6 rounded-md hover:bg-[#0d5a63] focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
