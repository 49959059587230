import React, { useState, useEffect } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
import Section7 from '../section7';

ChartJS.register(ArcElement, Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale);

export default function Savingsgoal() {
  const [targetAmount, setTargetAmount] = useState(5000);
  const [currentSavings, setCurrentSavings] = useState(500);
  const [monthlyContribution, setMonthlyContribution] = useState(200);
  const [timeframe, setTimeframe] = useState(24);
  const [interestRate, setInterestRate] = useState(1.5);

  const [endBalance, setEndBalance] = useState(0);
  const [interestEarned, setInterestEarned] = useState(0);
  const [goalProgress, setGoalProgress] = useState(0);
  const [savingsData, setSavingsData] = useState([]);

  useEffect(() => {
    let totalSavings = currentSavings;
    const monthlyRate = interestRate / 100 / 12;
    const data = [];

    for (let i = 0; i < timeframe; i++) {
      totalSavings += monthlyContribution;
      totalSavings += totalSavings * monthlyRate;
      data.push(totalSavings);
    }

    const totalInterest = totalSavings - (currentSavings + monthlyContribution * timeframe);

    setEndBalance(totalSavings.toFixed(2));
    setInterestEarned(totalInterest.toFixed(2));
    setGoalProgress(((currentSavings / targetAmount) * 100).toFixed(2));
    setSavingsData(data);
  }, [targetAmount, currentSavings, monthlyContribution, timeframe, interestRate]);

  const doughnutData = {
    labels: ['Current Savings', 'Remaining Amount'],
    datasets: [
      {
        data: [currentSavings, targetAmount - currentSavings],
        backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };


  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };

  return (
    <div>
      <div className='bg-[#ECE5C7]'>
        <div className="flex-shrink-0">
          <h1 className="text-3xl bg-white/40 backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]">Stakeplot</h1>
        </div>
        <div className='text-3xl backdrop-blur-sm border rounded-3xl p-3 text-[#116a7b]'>
          <h1>Savings Goal Calculator</h1>
        </div>
        <div className="max-w-md p-6 mx-auto my-10 border-4 border-white rounded-3xl">
          {/* Target amount input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="targetAmount" className="block text-sm font-medium text-[#116a7b]">
              Target Amount
            </label>
            <input
              type="range"
              id="targetAmount"
              name="targetAmount"
              min="1000"
              max="100000"
              step="1000"
              value={targetAmount}
              onChange={(e) => setTargetAmount(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${targetAmount}`}</div>
          </div>
          {/* Current savings input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="currentSavings" className="block text-sm font-medium text-[#116a7b]">
              Current Savings
            </label>
            <input
              type="range"
              id="currentSavings"
              name="currentSavings"
              min="0"
              max="100000"
              step="100"
              value={currentSavings}
              onChange={(e) => setCurrentSavings(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${currentSavings}`}</div>
          </div>
          {/* Monthly contribution input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="monthlyContribution" className="block text-sm font-medium text-[#116a7b]">
              Monthly Contribution
            </label>
            <input
              type="range"
              id="monthlyContribution"
              name="monthlyContribution"
              min="100"
              max="10000"
              step="100"
              value={monthlyContribution}
              onChange={(e) => setMonthlyContribution(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${monthlyContribution}`}</div>
          </div>
          {/* Timeframe input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="timeframe" className="block text-sm font-medium text-[#116a7b]">
              Timeframe (Months)
            </label>
            <input
              type="range"
              id="timeframe"
              name="timeframe"
              min="1"
              max="360"
              step="1"
              value={timeframe}
              onChange={(e) => setTimeframe(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${timeframe} months`}</div>
          </div>
          {/* Interest rate input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="interestRate" className="block text-sm font-medium text-[#116a7b]">
              Interest Rate (%)
            </label>
            <input
              type="range"
              id="interestRate"
              name="interestRate"
              min="0"
              max="10"
              step="0.1"
              value={interestRate}
              onChange={(e) => setInterestRate(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${interestRate}%`}</div>
          </div>
          {/* Display savings goal details */}
          <div className="mt-8">
            <div className="text-xl text-[#116a7b] font-semibold">Savings Goal Progress:</div>
            <div className="text-[#116a7b]">End Balance: ₹{endBalance}</div>
            <div className="text-[#116a7b]">Interest Earned: ₹{interestEarned}</div>
            <div className="text-[#116a7b]">Progress: {goalProgress}%</div>
          </div>
          {/* Doughnut chart to visualize progress */}
          <div className="m-2 w-[350px] flex justify-center">
            <Doughnut data={doughnutData} options={options} />
          </div>
        </div>
        <div className='mx-4'>
          <h2 className='text-2xl font-bold'>Savings Goal Calculator</h2>
          <p>The Savings Goal Calculator helps you plan and track your progress towards achieving a financial target over a specified period. By inputting key parameters, you can estimate how much you need to save and the potential interest earned to reach your goal.</p>
          <h1 className='text-lg py-2 font-bold'>How to Use the Calculator:</h1>
          <h2 className='font-bold'>Target Amount:</h2>
          <p>Adjust the slider to set the desired savings goal amount.</p>
          <h2 className='font-bold'>Current Savings:</h2>
          <p>Use the slider to set the current amount of savings you have accumulated.</p>
          <h2 className='font-bold'>Monthly Contribution:</h2>
          <p>Set the monthly amount you plan to contribute towards your savings goal.</p>
          <h2 className='font-bold'>Timeframe (Months):</h2>
          <p>Adjust the slider to set the duration in months over which you aim to achieve your savings goal.</p>
          <h2 className='font-bold'>Interest Rate (%):</h2>
          <p>Set the expected annual interest rate for your savings.</p>
          <h2 className='text-lg py-2 font-bold'>How It Works:</h2>
          <div className='border border-black rounded-lg p-2'>
          <h2 className='font-bold'>Monthly Savings Calculation:</h2>
          <p>The calculator determines the total savings by adding your monthly contributions and the interest earned each month.</p>
          <h2 className='font-bold'>End Balance Calculation:</h2>
          <p>This represents the projected total savings at the end of the specified timeframe, considering both contributions and accrued interest.</p>
          <h2 className='font-bold'>Interest Earned Calculation:</h2>
          <p>The calculator estimates the total interest earned over the savings period based on the interest rate and contributions.</p>
          <h2 className='font-bold'>Goal Progress Tracking:</h2>
          <p>The progress towards your savings goal is displayed as a percentage, indicating how close you are to reaching your target amount.</p>
          </div>
          <p>By utilizing this tool, you can effectively plan and monitor your savings journey, ensuring you stay on track to achieve your financial objectives.</p>
        </div>
      </div>
      <Section7></Section7>
    </div>
  );
}
