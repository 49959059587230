import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import Section7 from '../section7';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

export default function Creditcardpayoff() {
  const [creditCardBalance, setCreditCardBalance] = useState(5000); // Initial credit card balance
  const [creditCardInterestRate, setCreditCardInterestRate] = useState(18); // Annual interest rate for credit card
  const [monthlyPayment, setMonthlyPayment] = useState(200); // Monthly payment towards credit card debt

  const [monthsToPayOff, setMonthsToPayOff] = useState(0); // Months to pay off credit card debt
  const [totalInterestPaid, setTotalInterestPaid] = useState(0); // Total interest paid

  // Calculate credit card payoff details whenever there's a change in inputs
  useEffect(() => {
    // Calculate monthly interest rate
    const monthlyInterestRate = creditCardInterestRate / 12 / 100;

    // Calculate months to pay off debt
    const monthsToPayOff =
      Math.log(monthlyPayment / (monthlyPayment - creditCardBalance * monthlyInterestRate)) /
      Math.log(1 + monthlyInterestRate);

    // Calculate total interest paid
    const totalInterestPaid = monthsToPayOff * monthlyPayment - creditCardBalance;

    // Update state with calculated values
    setMonthsToPayOff(Math.ceil(monthsToPayOff));
    setTotalInterestPaid(Math.round(totalInterestPaid));
  }, [creditCardBalance, creditCardInterestRate, monthlyPayment]);

  // Data for Doughnut chart
  const data = {
    labels: ['Principal', 'Interest'],
    datasets: [
      {
        data: [creditCardBalance, totalInterestPaid],
        backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(75, 192, 192, 0.6)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };

  return (
    <div className='bg-[#ECE5C7] '>
      <div >
        <div className="flex-shrink-0">
          <h1 className="text-3xl bg-white/40 backdrop-blur-sm border rounded-3xl  p-3 text-[#116a7b]">Stakeplot</h1>
        </div>
        <div className='text-3xl backdrop-blur-sm border rounded-3xl  p-3 text-[#116a7b]'>
          <h1>Credit Card Payoff Calculator</h1>
        </div>
        <div className="max-w-md p-6 mx-auto my-10 border-4 border-white rounded-3xl">
          {/* Credit card balance input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="creditCardBalance" className="block text-sm font-medium text-[#116a7b]">
              Credit Card Balance
            </label>
            <input
              type="range"
              id="creditCardBalance"
              name="creditCardBalance"
              min="1000"
              max="100000"
              step="100"
              value={creditCardBalance}
              onChange={(e) => setCreditCardBalance(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${creditCardBalance}`}</div>
          </div>
          {/* Credit card interest rate input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="creditCardInterestRate" className="block text-sm font-medium text-[#116a7b]">
              Credit Card Interest Rate (%)
            </label>
            <input
              type="range"
              id="creditCardInterestRate"
              name="creditCardInterestRate"
              min="1"
              max="30"
              step="0.1"
              value={creditCardInterestRate}
              onChange={(e) => setCreditCardInterestRate(parseFloat(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`${creditCardInterestRate}`}</div>
          </div>
          {/* Monthly payment input */}
          <div className="mb-5 bg-white/60 backdrop-blur-sm border rounded-3xl p-2">
            <label htmlFor="monthlyPayment" className="block text-sm font-medium text-[#116a7b]">
              Monthly Payment
            </label>
            <input
              type="range"
              id="monthlyPayment"
              name="monthlyPayment"
              min="10"
              max="5000"
              step="10"
              value={monthlyPayment}
              onChange={(e) => setMonthlyPayment(parseInt(e.target.value))}
              className="w-full h-2 bg-[#C2DEDC] rounded-lg appearance-none cursor-pointer dark:bg-[#116a7b]"
            />
            <div className="text-right">{`₹${monthlyPayment}`}</div>
          </div>
          {/* Display credit card payoff details */}
          <div className="mt-8">
            <div className="text-xl text-[#116a7b] font-semibold">Credit Card Payoff Details:</div>
            <div className="text-[#116a7b]">Months to Pay Off: {monthsToPayOff}</div>
            <div className="text-[#116a7b]">Total Interest Paid: ₹{totalInterestPaid}</div>
          </div>
          {/* Doughnut chart to visualize principal and interest */}
          <div className="m-2 w-[350px] flex justify-center">
            <Doughnut data={data} options={options} />
          </div>
        </div>
        <div className='mx-4'>
          <h2 className='text-xl font-bold'>Credit Card Payoff Calculator :</h2>
          <h1>The Credit Card Payoff Calculator helps you determine how long it will take to pay off your credit card debt and the total interest you will pay over time.</h1>
          <h2 className='text-lg py-2 font-bold'>How to Use:</h2>
          <h1>1. Credit Card Balance:</h1>
            <p> Input: Use the slider to set your current balance (e.g., ₹5000).</p>
          <h1>2. Credit Card Interest Rate:</h1>
            <p> Input: Use the slider to set your annual interest rate (e.g., 18%).</p>
          <h1>3. Monthly Payment:</h1>
            <p> Input: Use the slider to set your planned monthly payment (e.g., ₹200).</p>
          <h2 className='text-lg py-2 font-bold'>How It Works:</h2>
          <div className='border border-black rounded-lg p-2'>
          <h1>Monthly Interest Rate:</h1>
            <p> Converts the annual interest rate to a monthly rate.</p>
          <h1>Months to Pay Off Debt:</h1>
            <p> Calculates the number of months to pay off the debt using your balance, monthly payment, and interest rate.</p>
          <h1> Total Interest Paid:</h1>
            <p> Computes the total interest paid over the repayment period.</p>
          <h2 className='text-lg'>Payoff Details:</h2>
          <h3>Months to Pay Off:</h3>
            <p>Displays the number of months required to pay off your debt.</p> 
            <p>Total Interest Paid:</p>
            <p>Shows the total interest you will pay over the repayment period.</p>
            </div>
          <h2 className='py-4 text-lg'>This tool helps you make informed financial decisions by visualizing the <span className='text-2xl underline'> impact of different payment strategies on your debt repayment timeline and total interest cost.</span>  Adjust the inputs to explore various scenarios and choose the best strategy for managing your credit card debt.</h2>
        </div>
      </div>
      <Section7></Section7>
    </div>
  );
}
