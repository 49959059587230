import React,{useEffect} from 'react'
import Logo1 from '/Users/adnan/Desktop/w2/client/src/images/logo1.svg'

export default function Policy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
}, []);
  return (
    <div>
        <div className='pt-4'>
        <div className='flex border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2'>
          <div className='flex justify-center items-center'>
          <img className='w-8 h-8 rounded-full mx-1' src={Logo1} alt=''/>
          </div>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <div className="drop-main">
          <div className="S animate-ease">S</div>
            <div className="T animate-ease">t</div>
            <div className="A animate-ease">a</div>
            <div className="K animate-ease">k</div>
            <div className="E animate-ease">e</div>
            <div className="P animate-ease">p</div>
            <div className="L animate-ease">l</div>
            <div className="O animate-ease">o</div>
            <div className="T animate-ease">t</div>
          </div>
          </div>
        </div>
        </div>
        </div>
        <div>
            <h1 className='p-4 text-2xl'>Privacy Policy</h1>
            <h1 className='px-4 text-xl'>Effective date : 15-07-2024</h1>
            <h1 className='px-4 pt-4'>Introduction</h1>
            <h1 className='px-4'>Welcome to Stakeplot! Your privacy is of utmost importance to us. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use the Stakeplot app. By using our app, you agree to the terms outlined in this policy.</h1>
            <h1 className='px-4 font-bold py-2'>Interpretations and Definitions</h1>
            <h1 className='px-4'>Interpretations :</h1>
            <h1 className='px-4'>You and Your refer to the user of the Stakeplot app.We, Us, and Our refer to Stakeplot and its operators.App refers to the Stakeplot mobile application.</h1>
            <h1 className='px-4 font-bold py-2'>Definitions</h1>
            <h1 className='px-4'>Personal Data means any information that relates to an identified or identifiable individual.</h1>
            <h1 className='px-4'>Usage Data refers to data collected automatically, either generated by the use of the app or from the app infrastructure itself.</h1>
            <h1 className='px-4'>Cookies are small files stored on your device (computer or mobile device).</h1>
            <h1 className='px-4'>Data Controller refers to the natural or legal person who determines the purposes and means of processing personal data.</h1>
            <h1 className='px-4'>Data Subject is any living individual who is using our app and is the subject of personal data.</h1>
            <h1 className='px-4 font-bold py-2'>Information Collection</h1>
            <h1 className='px-4'>Types of Information Collected :</h1>
            <h1 className='px-4'>Name: We collect your name to personalize your experience within the app.</h1>
            <h1 className='px-4'>Email Address: Your email address is collected for account verification, communication, and recovery purposes.</h1>
            <h1 className='px-4'>Phone Number: We use your phone number to provide account-related notifications and ensure secure access to your account.</h1>
            <h1 className='px-4'>Methods of Collection :</h1>
            <h1 className='px-4'>Account Registration : We collect information directly from you when you register for an account on the Stakeplot app.</h1>
            <h1 className='px-4 font-bold py-2'>Use of Information</h1>
            <h1 className='px-4'>Purpose of Use:</h1>
            <h1 className='px-4'>Providing Services : Your information helps us deliver the features and functionalities of the Stakeplot app.</h1>
            <h1 className='px-4'>Personalized Insights : We analyze your app data to offer customized financial insights and recommendations tailored to your financial goals and habits.</h1>
            <h1 className='px-4 font-bold py-2'>Sharing of Information</h1>
            <h1 className='px-4'>Third-Party Services :</h1>
            <h1 className='px-4'>We do not share your personal information with any third-party service providers.</h1>
            <h1 className='px-4'>Legal Obligations :</h1>
            <h1 className='px-4'>Your information may be disclosed if required by law or in response to valid requests by government authorities.</h1>
            <h1 className='px-4 font-bold py-2'>Data Security</h1>
            <h1 className='px-4'>Protection Measures</h1>
            <h1 className='px-4'>Cloud-Based Servers: Your data is stored on secure cloud servers that are protected against unauthorized access.</h1>
            <h1 className='px-4'>Data Encryption: We use encryption to safeguard your information during transmission and storage.</h1>
            <h1 className='px-4 font-bold py-2'>User Rights</h1>
            <h1 className='px-4'>Access and Control :</h1>
            <h1 className='px-4'>Access: You can view the information you have provided to us within the app.</h1>
            <h1 className='px-4'>Update: You can update your personal information at any time through the app settings.</h1>
            <h1 className='px-4'>Delete: You have the right to delete your account and personal information from our app whenever you choose.</h1>
            <h1 className='px-4 font-bold py-2'>Cookies and Tracking Technologies</h1>
            <h1 className='px-4'>We do not use cookies or other tracking technologies to collect information about your online activities.</h1>
            <h1 className='px-4 font-bold py-2'>Children's Privacy</h1>
            <h1 className='px-4'>Users must be above the age of 13 to use the Stakeplot app. We do not knowingly collect information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</h1>
            <h1 className='px-4 font-bold py-2'>Social Sharing</h1>
            <h1 className='px-4'>Community Features :</h1>
            <h1 className='px-4'>Social Sharing: Our app includes a social sharing feature where users can post queries and share images with the community. Please be mindful of the information you choose to share publicly, as it can be seen by other users.</h1>
            <h1 className='px-4'>Privacy in Community Interactions :</h1>
            <h1 className='px-4'>We encourage respectful and responsible sharing. Avoid posting sensitive personal information in public forums to protect your privacy and security.</h1>
            <h1 className='px-4'>Data Retention :</h1>
            <h1 className='px-4'>Retention Period: We retain your personal information for as long as your account is active or as needed to provide you services. If you delete your account, we will delete your personal information within a reasonable timeframe.</h1>
            <h1 className='px-4 font-bold py-2'>User Consent</h1>
            <h1 className='px-4'>By using our app, you consent to our collection, use, and sharing of your personal information as described in this Privacy Policy. You may withdraw your consent at any time by deleting your account.</h1>
            <h1 className='px-4 font-bold py-2'>Third-Party Links</h1>
            <h1 className='px-4'>Our app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites. We encourage you to read their privacy policies.</h1>
            <h1 className='px-4 font-bold py-2'>User Responsibilities</h1>
            <h1 className='px-4'>We recommend that you protect your account information and use strong, unique passwords for your Stakeplot account to ensure the security of your data.</h1>
            <h1 className='px-4 font-bold py-2'>Changes to the Privacy Policy</h1>
            <h1 className='px-4'>We may update this Privacy Policy from time to time. If we make any significant changes, we will notify you through the app, email. The updated policy will be effective from the date it is posted.</h1>
            <h1 className='px-4 font-bold py-2'>Contact Information</h1>
            <h1 className='px-4'>If you have any questions or concerns about this Privacy Policy, please contact us at our official email address: stakeplot@gmail.com.</h1>
            <h1 className='p-4'>Thank you for choosing Stakeplot! We value your trust and are committed to protecting your privacy. By using our app, you help us improve and provide better services tailored to your needs.</h1>
        </div>
    </div>
  )
}
