import React, { useState } from 'react';

export default function Section5() {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const questionsAndAnswers = [
    {
      question: "What is Stakeplot ?",
      answer: "StakePlot is like your financial superhero, helping you manage your money in a fun and exciting way. It's an easy-to-use platform that makes handling your finances feel like a breeze.",
    },
    {
      question: "Why to choose stakeplot ?",
      answer: "StakePlot is a seamless money management experience packed with powerful features tailored to your needs. From splitting bills with friends, scheduling payments to setting financial targets and tracking debts easily and much more.. StakePlot empowers you to take control of your finances effortlessly, making every financial goal achievable with ease and precision.",
    },
    {
      question: "What is community ?",
      answer: "The community is your virtual gathering spot where like-minded individuals come together to discuss personal finance matters, share valuable tips and advice, and engage in meaningful conversations about financial challenges and successes. It's your go-to hub for seeking support, gaining insights, and connecting with others on their financial journeys.",
    },
    {
      question: "Do i need to provide the bank or card details ?",
      answer: "StakePlot doesn't require any bank details from you, so you can rest assured that your sensitive information remains confidential and protected at all times.",
    }
    // Add more questions and answers as needed
  ];

  const handleQuestionClick = (index) => {
    setSelectedQuestion(index === selectedQuestion ? null : index);
  };

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-3xl m-4 font-bold mb-6">Frequently Asked Questions</h1>
      <div className="w-full md:flex">
        <div className="md:w-1/2 lg:w-1/2">
          {questionsAndAnswers.map((qa, index) => (
            <div key={index} className="m-4">
              <div
                className={`cursor-pointer border rounded-lg p-2 text-lg ${
                  selectedQuestion === index ? 'bg-slate-700 border-bg-slate-700 text-white' : 'border-white'
                }`}
                onClick={() => handleQuestionClick(index)}
              >
                <h2 className="text-xl font-semibold">{qa.question}</h2>
              </div>
              {selectedQuestion === index && (
                <div className="mt-2 p-2 border rounded-lg shadow-lg bg-white text-bg-slate-700 block md:hidden">
                  <p className='text-lg'>{qa.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        {selectedQuestion !== null ? (
          <div className="hidden p-2 border m-4 shadow-md rounded-lg border-black md:block md:w-1/2 lg:w-1/2">
            <div className="mt-2">
              <p className='text-lg'>{questionsAndAnswers[selectedQuestion].answer}</p>
            </div>
          </div>
        ) : (
          <div className="hidden border rounded-lg m-4 px-1 border-black md:block md:w-1/2 lg:w-1/2">
            <div className="mt-[40px] flex justify-center items-center">
              <p className='text-5xl'>StakePlot: Your go-to for fun, smart money management.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
