import React,{useState, useEffect} from 'react'
import credit from '/Users/adnan/Desktop/w2/client/src/images/calculator/credit-card.svg'
import rentvsbuy from '/Users/adnan/Desktop/w2/client/src/images/calculator/rentvsBuy.svg'
import auto from '/Users/adnan/Desktop/w2/client/src/images/calculator/Auto-calculator.svg'
import savings from '/Users/adnan/Desktop/w2/client/src/images/calculator/savings.svg'
import Trip from '/Users/adnan/Desktop/w2/client/src/images/calculator/Trip.svg'
import Section6 from '../section6'
import calculator from '/Users/adnan/Desktop/w2/client/src/images/calculator.svg'
import { Link } from 'react-router-dom'
import Logo1 from '/Users/adnan/Desktop/w2/client/src/images/logo.png'

export default function Calculatorhome() {
    const [menuOpen, setMenuOpen] = useState(false);
    useEffect(() => {
      window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };
  return (
    <div className='bg-[#ece5c7]'>
        <nav className="p-2 fixed top-0 left-0 w-full z-10">
      <div className="container flex justify-between items-center">
      <div className='flex border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2'>
          <div className='flex justify-center items-center'>
          <img className='w-8 h-8 rounded-full mx-1' src={Logo1} alt=''/>
          </div>
        <Link to='/'>
        <div className="text-[#116a7b] text-3xl ">
        <div className="wrapper-one">
          <div className="drop-main">
          <div className="S animate-ease">S</div>
            <div className="T animate-ease">t</div>
            <div className="A animate-ease">a</div>
            <div className="K animate-ease">k</div>
            <div className="E animate-ease">e</div>
            <div className="P animate-ease">p</div>
            <div className="L animate-ease">l</div>
            <div className="O animate-ease">o</div>
            <div className="T animate-ease">t</div>
          </div>
          </div>
        </div>
        </Link>
        </div>
        <div className="hidden md:flex border-2 bg-opacity-40 bg-white backdrop-blur-lg rounded-2xl border-white p-2">
          <Link to='/' className="text-[#116a7b] underline-animation m-2">Home</Link>
          <Link to='/blogs' className="text-[#116a7b] underline-animation m-2">Blogs</Link>
          <Link to='/calculators' className="text-[#116a7b] underline-animation m-2">Calculators</Link>
        </div>
        <div className="md:hidden flex items-center pr-6">
          <button onClick={toggleMenu} className="text-[#116a7b] focus:outline-none">
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
            </svg>
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className=" w-[100%] z-10 md:hidden bg-opacity-20 bg-white backdrop-blur-md rounded-2xl border-white p-2">
        <Link to='/' className="text-[#116a7b] underline-animation ">Home</Link>
        <Link to='/blogs' className="block py-2 text-gray-800 transition duration-300">
          <div className='flex'>
          <h2 className='underline-animation text-[#116a7b] flex-wrap'>Blogs</h2>
          </div>
          </Link>
        <div className="relative" >
        <Link to='/calculators' className="block py-2 text-gray-800 transition duration-300">
          <div className='flex'>
          <h2 className='underline-animation  text-[#116a7b] flex-wrap'>Calculators</h2>
          </div>
          </Link>
        </div>
      </div>
      )}
    </nav>
    <div className='pt-[80px] px-4 md:pt-[80px] md:p-[50px] lg:pt-[150px] lg:px-[150px]'>
      <div className='p-4 text-4xl flex justify-center items-center'>
        <h1>Calculators</h1>
      </div>
        <div className=' md:grid grid-cols-2 md:gap-4 '>
          <div>
          <div className='grid grid-cols-2 gap-4 mb-4'>
            <Link to='/credit-card-payoff-calculator' className='rounded-3xl bg-[#429CD0] md:h-[235px] text-center '>
            <div className='text-xl font-bold'>Credit card payoff</div>
            <div className='flex justify-center items-center'>
                <img className=' w-[150px] h-[150px]' src={credit} alt='calculator'/>
              </div>
              <h1 className='text-center'>Slash your credit card debt timeline!</h1>
            </Link>
            <Link  to='/emi-calculator'>
            <div className='rounded-3xl bg-[#AFA8BA] md:h-[235px] text-center'>
            <h1 className='text-xl font-bold'>Emi</h1>
              <div className='flex justify-center items-center'>
                <img className=' w-[150px] h-[150px]' src={calculator} alt='calculator'/>
              </div>
              <div>
              <h2>Calculate your loan repayment schedule.</h2>
              </div>
            </div>
            </Link>
          </div>
          <Link to='/rent-buy-calculator'>
            <div className='rounded-3xl mb-4 bg-[#EFA89C] h-[230px] md:h-[250px] '>
              <h1 className='text-center text-2xl font-bold'>Rent vs Buy calculator</h1>
            <div className='flex justify-center items-center'>
                <img className=' w-[150px] h-[150px]' src={rentvsbuy} alt='calculator'/>
              </div>
              <h1 className='text-center text-xl'>Confused: Renting or buying? Find out!</h1>
            </div>
          </Link>
          </div>
          <Link to='/auto-loan-calculator'>
          <div className='rounded-3xl bg-[#FFD77A] h-[230px] md:h-[500px]'>
          <h1 className='text-center text-2xl font-bold'>Auto Loan calculator</h1>
          <div className='flex justify-center items-center'>
            <img className=' w-[150px] h-[150px]' src={auto} alt='calculator'/>
          </div>
          <h1 className='text-center text-xl md:text-2xl'>Drift auto financing like a pro!</h1>
          <h2 className='text-start text-lg p-2 hidden md:block'>Calculate your potential auto loan payments in seconds with our intuitive calculator. Simply input the loan amount, interest rate, and loan term to get an instant estimate of your monthly payments. Take the guesswork out of car financing and make informed decisions about your next vehicle purchase.</h2>
          </div>
          </Link>
        </div>
        <div className='grid py-4 grid-cols-8 gap-4'>
        <Link className='col-span-4 md:pt-6 rounded-3xl bg-[#C3B1E7] h-[255px] md:h-[270px] text-center ' to='/savings-goal-calculator'>
          <div className=' text-xl font-bold'>Savings goal calculator</div>
          <div className='flex justify-center items-center'>
            <img className=' w-[150px] h-[150px]' src={savings} alt='calculator'/>
          </div>
            <h1 className='text-center'>Reach your savings goals with precision!</h1>
          </Link>
          <Link className='col-span-4 md:pt-6 rounded-3xl bg-[#A17959] h-[255px] md:h-[270px] text-center ' to='/trip-vacation-cost-calculator'>
          <div className='text-xl font-bold'>Trip Cost Calculator</div>
          <div className='flex justify-center items-center'>
                <img className=' w-[150px] h-[150px]' src={Trip} alt='calculator'/>
          </div>
            <h1 className='text-center'>Plan your trip budget with ease!</h1>
          </Link>
          </div>
      </div>
      <Section6></Section6>
    </div>
  )
}
