import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import track from '/Users/adnan/Desktop/w2/client/src/images/3-track.svg'
import set from '/Users/adnan/Desktop/w2/client/src/images/3-set.svg'
import set2 from '/Users/adnan/Desktop/w2/client/src/images/3-set2.svg'
import calculator from '/Users/adnan/Desktop/w2/client/src/images/3-calculators.svg'
import hub from '/Users/adnan/Desktop/w2/client/src/images/3-hub.svg'
import community from '/Users/adnan/Desktop/w2/client/src/images/3-community.svg'
import relax from '/Users/adnan/Desktop/w2/client/src/images/3-relax.svg'

export default function Section3() {
  useEffect(() => {
    AOS.init({duration:1000})
  })
  return (
    <div className='rounded-3xl bg-[#8CB6AD] p-2'>
      <h1 className='p-3 text-3xl'>How it works..!</h1>
      <div className='pt-4 px-4 md:p-[50px] lg:pt-[150px] lg:px-[150px]'>
        <div className='lg:grid grid-cols-2 lg:gap-4 '>
          <div className='m-2 rounded-3xl bg-[#FFD77A] lg:h-[550px] text-center pt-6 ' data-aos='flip-right'>
            <h1 className='text-3xl'>Track</h1>
            <div className='flex justify-center'>
              <img className='w-[60%] lg:w-[80%] h-[50%] lg:h-[60%]' src={track} alt='Track all your regular expenses easily'></img>
            </div>
            <p className='text-md text-gray-600 p-2'>Easily track all your expenses in one place with stakeplot, designed to simplify your budgeting and save you money.</p>
          </div>
          <div>
            <div className='m-2 rounded-3xl bg-[#EFA89C] lg:h-[275px] text-center' data-aos='zoom-out'>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center py-4  ">
            <div className=" justify-center items-center mb-4 p-4">
                <h3 className="text-lg font-semibold ">Scheduling</h3>
                <p className="text-gray-600 text-md lg:text-sm">Stay organized and on top of your finances with our advanced scheduling features, ensuring you never miss a bill or important financial deadline.</p>
              </div>
              <div className="relative m-auto w-[45%] lg:w-full h-[200px] lg:mt-[-60px]">
                <img
                  src={set}
                  alt="finance reminders"
                  className="absolute transform translate-x-[-40px] lg:translate-x-0 rotate-[-26deg] bg-slate-200 shadow-lg"
                />
                <img
                  src={set2}
                  alt="Expense tracker"
                  className="absolute transform rotate-[20deg] translate-x-[50px] translate-y-[40px] lg:translate-y-[2px] bg-yellow-200 shadow-xl"
                />
              </div>
            </div>
            </div>
          <div className='grid grid-cols-2 lg:gap-4 lg:py-4'>
            <div className='m-2 rounded-3xl bg-[#429CD0] lg:h-[250px] text-center' data-aos='flip-up'>
              <h1 className='text-3xl'>Hub</h1>
              <h2 className="text-gray-800 text-md">Get expert tips from our extensive personal finance articles.</h2>
              <div className='flex justify-center'>
                <img className='w-[150px] h-[150px]' src={hub} alt='finance hub for knowledge'></img>
              </div>
            </div>
            <div className='m-2 rounded-3xl bg-[#AFA8BA] lg:h-[250px] text-center' data-aos='flip-down'>
              <div className='flex justify-center items-center'>
                <img className=' w-[150px] h-[150px]' src={calculator} alt='finance calculators'/>
              </div>
              <div>
              <h1 className='text-3xl'>Calculators</h1>
              <h2 className="text-gray-800 text-sm">Quickly solve finance calculations with our easy-to-use calculators.</h2>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className='lg:grid grid-cols-12 lg:gap-2'>
          <div className='m-2 col-span-9 rounded-3xl bg-[#C3B1E7] lg:h-[270px] text-center' data-aos='flip-right'>
            <h1 className='text-3xl'>Community and friends</h1>
            <div className='md:flex'>
            <div className='flex justify-center items-center'>
            <img src={community} alt='community finance' className='w-[200px] h-[200px] mx-2'></img>
            </div>
            <div className='md:flex md:justify-center md:items-center'>
            <h2 className='m-4 text-md'>Join our vibrant community to connect with friends, share tips, and enjoy a fun, collaborative approach to managing your personal finances.</h2>
            </div>
            </div>
          </div>
          <div className='m-2 col-span-3 lg:col-span-3 rounded-3xl bg-[#FFBC9D] lg:h-[270px] text-center' data-aos='flip-left'>
          <h1 className='text-3xl'>Relax</h1>
              <h2 className='lg:text-sm'>Experience a relaxed and stress-free approach to managing your finances with stakeplot.</h2>
              <div className='flex justify-center'>
                <img className='w-[150px] h-[150px]' src={relax} alt='stress free finance'></img>
              </div>
          </div>
          </div>
      </div>
    </div>
  )
}
